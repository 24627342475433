import { Modal, useTranslation, Box, Text, Flex, Icon, IconButton, TextInput } from "@familyzone/component-library";
import React, { useState } from "react";
import { FilteringRule, Signature } from "../../pages/filtering/types";
import { urlPolicySearch, urlSignatureSearch } from "../../pages/filtering/ApiHelpers";
import SignatureHierarchy, { SignatureTree } from "./SignatureHierarchy";
import NoContentDisplay from "../../pages/filtering/NoContentDisplay";
import StatusChip from "./StatusChip";

export const headerText = "Category Search";
export const headerDescText = "Check the category that a website exists in.";
export const websiteLabelText = "Website";
export const categoryLabelText = "Category";
export const policyLabelText = "Matching Policies";
export const websitePlaceholderText = "e.g. youtube.com";
export const noResultsText = "Uncategorised Website";
export const blockedText = "Blocked";
export const allowedText = "Allowed";
export const enabledText = "Enabled";
export const disabledText = "Disabled";
export const noResultsDescription =
  "This website is not categorised. If you believe this website requires a category, please contact our support team.";
export const reviewText = "If you believe this website should be in a different category, please contact our support team.";
export interface SignatureSearchModalProps {
  open: boolean;
  onClose: () => void;
  onSignatureClick?: (rule: FilteringRule) => void;
  signatures?: Signature[];
}

const SignatureSearchModal: React.FC<SignatureSearchModalProps> = ({ open, onClose, onSignatureClick = () => "", signatures }) => {
  const { t } = useTranslation();

  const [urlErrorText, setURLErrorText] = useState("");
  const [url, setUrl] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const [signaturesResult, setSignaturesResult] = useState<Signature[]>([]);
  const [submittedUrl, setSubmittedUrl] = useState("");
  const [rulesResult, setRulesResult] = useState<FilteringRule[]>([]);

  const handleChangeURL = (e: React.ChangeEvent<HTMLInputElement>) => {
    // https://stackoverflow.com/a/49849482
    if (
      !e.target.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g) ||
      /\s/.test(e.target.value)
    ) {
      setURLErrorText("Invalid url entered");
    } else {
      setURLErrorText("");
    }

    setUrl(e.target.value);
  };

  const populateResult = async () => {
    try {
      setIsLoaded(false);
      const newUrl = url.replace("https://", "").replace("http://", "").split("/")[0];
      const result = await urlSignatureSearch(newUrl);
      const matchingSignatures: Signature[] = [];
      if (signatures) {
        if ("signature" in result) {
          const signatureDetails = signatures.find((s) => s.id === result.signature);
          if (signatureDetails) matchingSignatures.push(signatureDetails);
        }
        if ("category" in result) {
          const signatureDetails = signatures.find((s) => s.id === result.category);
          if (signatureDetails) matchingSignatures.push(signatureDetails);
        }
        if ("subCategory" in result) {
          const signatureDetails = signatures.find((s) => s.id === result.subCategory);
          if (signatureDetails) matchingSignatures.push(signatureDetails);
        }
      }
      const matchingPolicy = await urlPolicySearch(newUrl);
      setUrl(newUrl);
      setSubmittedUrl(newUrl);
      setSignaturesResult(matchingSignatures);
      setRulesResult(matchingPolicy.matching_rules);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoaded(true);
    }
  };

  const handlePopulateResult = () => void populateResult();

  const getSignatureList = (): Signature[] => {
    const category: Signature | undefined = signaturesResult.find((s) => s.is_category);
    const subCategory: Signature | undefined = signaturesResult.find((s) => s.is_sub_category);
    const signature: Signature | undefined = signaturesResult.find((s) => !s.is_category && !s.is_sub_category);

    const localSignatureList: Signature[] = [];
    if (category) {
      localSignatureList.push(category);
    }
    if (subCategory) {
      localSignatureList.push(subCategory);
    }
    if (signature) {
      localSignatureList.push(signature);
    }
    return localSignatureList;
  };

  const signatureList = getSignatureList();

  const getSignatureTree = (): SignatureTree[] => {
    const tree: SignatureTree[] = [];

    signatureList.forEach((s, i) => {
      if (i === 0) {
        tree.push({
          name: s.name,
          id: s.id,
          icon: s.favicon_url,
          important: false,
          included: true,
          action: -1,
          children: [],
        });
      }

      if (i === 1) {
        tree[0].included = false;
        tree[0].children.push({
          name: s.name,
          id: s.id,
          icon: s.favicon_url,
          important: false,
          included: true,
          action: -1,
          children: [],
        });
      }

      if (i === 2) {
        tree[0].children[0].included = false;
        tree[0].children[0].children.push({
          name: s.name,
          id: s.id,
          icon: s.favicon_url,
          important: false,
          included: true,
          action: -1,
          children: [],
        });
      }
    });

    return tree;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handlePopulateResult();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={open} onClose={handleClose} headerText={headerText} size="md">
      <Flex flexDirection="column">
        <Box pt="sp24">
          <Text color="text.paragraph.regular">{headerDescText}</Text>
        </Box>
        <Flex pt="sp24">
          <Text color="text.paragraph.regular" fontSize="sm">
            {t(websiteLabelText)}
          </Text>
        </Flex>
        <Flex pt="sp4" direction="row" alignItems="center">
          <Box flexGrow={1}>
            <form onSubmit={handleSubmit}>
              <TextInput
                aria-label={t(websiteLabelText)}
                placeholder={t(websitePlaceholderText)}
                onChange={handleChangeURL}
                value={url}
                isDisabled={!isLoaded}
              />
            </form>
          </Box>
          <Box pl="sp16">
            <IconButton
              icon={<Icon icon="fa-search" />}
              variant="secondary"
              onClick={handlePopulateResult}
              aria-label="small-icon-search"
              size="default"
              disabled={!isLoaded || !url}
              data-testid="signature-search-btn"
            />
          </Box>
        </Flex>
        <Flex pb="sp24" pt="sp4">
          <Text color="accent.red.400" fontSize="sm">
            {urlErrorText}
          </Text>
        </Flex>

        {!!submittedUrl && signaturesResult.length !== 0 && (
          <Flex>
            <Text pb="sp8">{t(categoryLabelText)}</Text>
          </Flex>
        )}

        <SignatureHierarchy signatureTree={getSignatureTree()} />

        {!!submittedUrl && rulesResult.length !== 0 && (
          <Flex pt="sp24">
            <Text>{t(policyLabelText)}</Text>
          </Flex>
        )}

        {!!submittedUrl && rulesResult.length !== 0 && (
          <Box minWidth="250px" pt="sp4" flexDirection="column">
            {rulesResult.map((rule) => (
              <Flex
                key={rule.id}
                mb="sp8"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                p="sp8"
                backgroundColor="neutrals.10"
                borderRadius={16}
                mt="sp10"
              >
                <Flex>
                  <Text
                    fontWeight="medium"
                    whiteSpace="nowrap"
                    ml="sp8"
                    mr="sp16"
                    textOverflow="ellipsis"
                    maxWidth="300px"
                    overflow="hidden"
                    flexGrow={1}
                  >
                    {rule.name}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center" width="100%">
                  <Flex>
                    <StatusChip action={rule.action} />
                  </Flex>
                </Flex>
                <IconButton
                  icon={<Icon icon="fa-pencil" sx={{ color: "text.paragraph.light" }} />}
                  onClick={() => {
                    onSignatureClick(rule);
                  }}
                  variant="ghost"
                  aria-label="small-icon-search"
                  size="sm"
                  disabled={!isLoaded || !url}
                />
              </Flex>
            ))}
          </Box>
        )}
        {!!submittedUrl && signaturesResult.length !== 0 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            backgroundColor="neutrals.10"
            p="sp16"
            borderRadius={16}
            mt="sp16"
          >
            <Text textAlign="center" color="neutrals.400" maxWidth={280}>
              {t(reviewText)}
            </Text>
          </Box>
        )}
        {!!submittedUrl && signaturesResult.length === 0 && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <NoContentDisplay title={t(noResultsText)} text={t(noResultsDescription)}></NoContentDisplay>
          </Box>
        )}
      </Flex>
    </Modal>
  );
};

export default SignatureSearchModal;
