import { Box, Button, Flex, Modal, ModalBody, Text, Tooltip, useToast, useTranslation } from "@familyzone/component-library";
import React, { useCallback, useEffect, useState } from "react";
import SessionStore from "../../../stores/SessionStore";
import { LinkedGuardian } from "../../../types/Community";
import { UserCMS } from "../../../types/User";
import { UserUMS } from "../../../types/Users";
import Api from "../../../utils/Api";
import AddParentModal from "../../modals/AddParentModal";
import SearchParentModal from "../../modals/SearchParentModal";
import ParentDetails from "../ParentDetails";
import { isUserObject } from "../../../utils/api/Users";
import { CommunityConfigStore, useCommunityConfigStore } from "../../../storez/CommunityConfigStore";

interface ManageUserGuardiansProps {
  user: UserCMS | UserUMS;
  guardians: LinkedGuardian[];
  addParentToolTipMessage: string;
  canLinkGuardian: boolean;
  saving: boolean;
  onAddGuardian: (guardian: LinkedGuardian) => void;
  onLinkGuardian: (guardian: LinkedGuardian) => void;
  onDeleteGuardian: (guardianId: string) => void;
  onFailure: (message: string) => void;
}

export const ManageUserGuardians: React.FC<ManageUserGuardiansProps> = ({
  user,
  guardians,
  addParentToolTipMessage,
  canLinkGuardian,
  saving,
  onAddGuardian,
  onLinkGuardian,
  onFailure,
  onDeleteGuardian,
}) => {
  const deleteGuardian = () => {
    const title = deleteGuardianModal.unlink ? "Disconnect Parent" : "Delete Parent";
    const studentName = isUserObject(user) ? `${user.firstName ?? ""} ${user.lastName ?? ""}` : `${user.fname ?? ""} ${user.lname ?? ""}`;
    return (
      <Modal
        size="sm"
        headerText={title}
        isOpen={deleteGuardianModal.showModal}
        onClose={() => handleShowDeleteGuardianModal(false, "", "", "", false)}
      >
        <ModalBody>
          <Box>
            <Text>
              Are you sure you want to remove the link between parent: <Text as="b">{deleteGuardianModal.guardianName}</Text> and student:{" "}
              <Text as="b">{studentName}</Text> from the system?
            </Text>
            <Box textAlign="center">
              <Button variant="danger" mt="sp16" onClick={handleDeleteGuardian}>
                Yes, {title}
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </Modal>
    );
  };

  const handleShowDeleteGuardianModal = (
    showModal: boolean,
    guardianId: string,
    guardianName: string,
    guardianEmail: string,
    unlink: boolean
  ) => {
    setDeleteGuardianModal({
      showModal: showModal,
      guardianId: guardianId,
      guardianName: guardianName,
      guardianEmail: guardianEmail,
      unlink: unlink,
    });
  };

  const { successToast, errorToast } = useToast();

  const handleDeleteGuardian = () => {
    const showSuccessToast = (title: string, message: string) => {
      successToast({
        title: t(title),
        description: t(message),
        duration: 3000,
        isClosable: true,
      });
    };

    const showErrorToast = (message: string) => {
      errorToast({
        title: t("Something went wrong"),
        description: t(message),
        duration: 3000,
        isClosable: true,
      });
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const device = SessionStore.getSelectedDevice();
    const username = user.username;
    const guardianId = deleteGuardianModal.guardianId;
    const guardianEmail = deleteGuardianModal.guardianEmail;
    const unlink = deleteGuardianModal.unlink;

    const resetModalState = {
      showModal: false,
      guardianId: "",
      guardianName: "",
      guardianEmail: "",
      unlink: false,
    };

    if (unlink) {
      Api.delete(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `/config/ajax/devices/${device}/users/${username}/guardians/${guardianEmail}/claim`,
        {},
        function () {
          showSuccessToast(
            "Success",
            "Your request to disconnect this parent has been received. Check back shortly to confirm the disconnection has completed"
          );
          setDeleteGuardianModal(resetModalState);
        },
        function () {
          showErrorToast("There was an error removing the connection between parent and student. Please try again later");
          setDeleteGuardianModal(resetModalState);
        }
      );
    } else {
      Api.delete(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `/config/ajax/devices/${device}/users/${username}/guardians/${guardianId}`,
        {},
        function () {
          showSuccessToast("Success", "The parent has been successfully unlinked for the student");
          setDeleteGuardianModal(resetModalState);
          onDeleteGuardian(guardianId);
        },
        function () {
          showErrorToast("There was an error removing the link between parent and student. Please try again later");
          setDeleteGuardianModal(resetModalState);
        }
      );
    }
  };

  const [deleteGuardianModal, setDeleteGuardianModal] = useState({
    showModal: false,
    guardianId: "",
    guardianName: "",
    guardianEmail: "",
    unlink: true,
  });

  const { t } = useTranslation();

  const [showSearchGuardianModal, setShowSearchGuardianModal] = useState<boolean>(false);
  const [showAddGuardianModal, setShowAddGuardianModal] = useState<boolean>(false);

  const handleShowAddGuardianModal = () => {
    setShowSearchGuardianModal(false);
    setShowAddGuardianModal(true);
  };

  const handleAddNewGuardian = (guardian: LinkedGuardian) => {
    setShowAddGuardianModal(false);
    onAddGuardian(guardian);
  };

  const handleLinkGuardian = (guardian: LinkedGuardian) => {
    setShowSearchGuardianModal(false);
    onLinkGuardian(guardian);
  };

  const [storedCommunityConfig, fetchCommunityConfig] = useCommunityConfigStore(
    useCallback((state: CommunityConfigStore) => [state.config, state.fetch] as const, [])
  );

  useEffect(() => {
    void fetchCommunityConfig();
  }, [fetchCommunityConfig]);

  return (
    <>
      <Text fontFamily="heading" fontSize="xl" color="text.title" mb="sp4" pb="md" role="heading">
        Parent Details
      </Text>
      <Box>
        <Flex flexWrap="wrap">
          {guardians.map((item: LinkedGuardian) => (
            <ParentDetails
              key={item.id}
              disableActions={user.archived}
              parent={item}
              policyDelegationEnabled={!!storedCommunityConfig?.policyDelegation?.allowPolicyDelegation}
              handleShowDeleteModal={handleShowDeleteGuardianModal}
            />
          ))}
        </Flex>
      </Box>
      <Box width="120px">
        <Tooltip label={t(addParentToolTipMessage)} placement="top" variant="dark" isDisabled={canLinkGuardian && !saving}>
          <Box>
            <Button
              variant="secondary"
              onClick={() => setShowSearchGuardianModal(true)}
              disabled={!canLinkGuardian || saving || user.archived}
            >
              Add Parent
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <SearchParentModal
        show={showSearchGuardianModal}
        handleHide={() => setShowSearchGuardianModal(false)}
        handleSuccess={handleLinkGuardian}
        handleFail={onFailure}
        handleAddParent={handleShowAddGuardianModal}
        guardianIdsToExclude={guardians.map((g) => g.id)}
        user={user}
      />
      <AddParentModal
        show={showAddGuardianModal}
        handleSuccess={handleAddNewGuardian}
        handleHide={() => setShowAddGuardianModal(false)}
        handleFail={onFailure}
        user={user}
      />
      {deleteGuardian()}
    </>
  );
};
