export enum AuditScope {
  CALENDAR = "CALENDAR",
  CAMPAIGN = "CAMPAIGN",
  COMMUNITY = "COMMUNITY",
  SAFETY_NET = "SAFETY_NET",
}

export interface AuditRecord {
  id: string;
  entityId: string;
  entityType: string;
  entityVersion?: number;
  operation?: string;
  before?: object;
  after?: object;
  scope: string;
  actor?: string;
  timestamp: string;
  summary?: string;
}
