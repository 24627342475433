import React, { useCallback, useState } from "react";
import { Box, Button, Modal, ModalBody, TextInput, useToast, useTranslation } from "@familyzone/component-library";
import { isValidEmail } from "../../utils/Validation";
import Api from "../../utils/Api";
import SessionStore from "../../stores/SessionStore";
import { Guardian } from "../../types/Community";
import { UserCMS } from "../../types/User";
import { successToastAddText, successToastTitle } from "../config/ManageUser/ManageUserHelpers";
import { UserUMS } from "../../types/Users";
import { isUserObject } from "../../utils/api/Users";

interface AddParentModalProps {
  show: boolean;
  handleHide: () => void;
  handleSuccess: (guardian: Guardian) => void;
  handleFail: (message: string) => void;
  user: UserCMS | UserUMS;
}

interface GuardianRequest {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  sourceType: string;
}

const AddParentModal: React.FC<AddParentModalProps> = ({ show, handleHide, handleSuccess, handleFail, user }: AddParentModalProps) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [firstNameChanged, setFirstNameChanged] = useState(false);
  const [lastNameChanged, setLastNameChanged] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);

  const [invalidFirstNameReason, setInvalidFirstNameReason] = useState<string | undefined>(undefined);
  const [invalidLastNameReason, setInvalidLastNameReason] = useState<string | undefined>(undefined);
  const [invalidEmailReason, setInvalidEmailReason] = useState<string | undefined>(undefined);

  const validInput: () => boolean = useCallback(() => {
    return (invalidFirstNameReason || invalidLastNameReason || invalidEmailReason) === undefined;
  }, [invalidFirstNameReason, invalidLastNameReason, invalidEmailReason]);

  const requiredFieldsChanged: () => boolean = useCallback(() => {
    return firstNameChanged && lastNameChanged && emailChanged;
  }, [firstNameChanged, lastNameChanged, emailChanged]);

  const validateFirstName = (firstName: string) => {
    let firstname_error = undefined;
    if (firstName.length === 0) {
      firstname_error = "First name is required";
    }
    setInvalidFirstNameReason(firstname_error);
  };

  const validateEmail = (email: string) => {
    let email_error = undefined;
    if (email.length === 0) {
      email_error = "Email is required";
    } else if (!isValidEmail(email)) {
      email_error = "Please enter a valid email address";
    }
    setInvalidEmailReason(email_error);
  };

  const validateLastName = (lastName: string) => {
    let lastname_error = undefined;
    if (lastName.length === 0) {
      lastname_error = "Last name is required";
    }
    setInvalidLastNameReason(lastname_error);
  };

  const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstNameChanged(true);
    setFirstName(event.target.value);
    validateFirstName(event.target.value);
  };

  const handleChangeMiddleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMiddleName(event.target.value);
  };

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastNameChanged(true);
    setLastName(event.target.value);
    validateLastName(event.target.value);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailChanged(true);
    setEmail(event.target.value);
    validateEmail(event.target.value);
  };

  const clearFields = () => {
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setEmail("");
    setFirstNameChanged(false);
    setLastNameChanged(false);
    setEmailChanged(false);
  };

  const handleClose = () => {
    clearFields();
    handleHide();
  };

  const { successToast } = useToast();
  const showSuccessToast = (title: string, message: string) => {
    successToast({
      title: t(title),
      description: t(message),
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSubmit = () => {
    const guardianToAdd: GuardianRequest = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      sourceType: "LOCAL",
    };

    const userToAdd = isUserObject(user)
      ? {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          distinguishedName: user.distinguishedName,
          sourceType: user.sourceType,
          username: user.username,
        }
      : {
          email: user.email,
          firstName: user.fname,
          lastName: user.lname,
          distinguishedName: user.dn,
          sourceType: user.provider,
          username: user.username,
        };

    Api.post(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
      `/config/ajax/devices/${SessionStore.getSelectedDevice()}/guardians`,
      {
        guardian: guardianToAdd,
        user: userToAdd,
      },
      function (result: Guardian) {
        clearFields();
        handleSuccess(result);
        showSuccessToast(successToastTitle, successToastAddText(user, result));
      },
      function (response: { status: number }) {
        if (response.status === 409) {
          handleFail(`The email address ${email} cannot be used. Please enter a different email.`);
        } else {
          handleFail("Sorry, we were unable to update the parent's details. Please try again later.");
        }
      }
    );
  };

  return (
    <Modal headerText={t("Add A New Parent")} isOpen={show} onClose={handleClose} size="sm">
      <ModalBody>
        <form className="modal-form">
          <TextInput
            label="First Name"
            placeholder=""
            name="first-name"
            value={firstName}
            onChange={handleChangeFirstName}
            isRequired={true}
            errorMessage={firstNameChanged ? invalidFirstNameReason : undefined}
          />
          <TextInput label="Middle Name" placeholder="" name="middle-name" value={middleName} onChange={handleChangeMiddleName} />
          <TextInput
            label="Last Name"
            placeholder=""
            name="last-name"
            value={lastName}
            onChange={handleChangeLastName}
            isRequired={true}
            errorMessage={lastNameChanged ? invalidLastNameReason : undefined}
          />
          <TextInput
            label="Email Address"
            placeholder=""
            name="email"
            value={email}
            onChange={handleChangeEmail}
            isRequired={true}
            errorMessage={emailChanged ? invalidEmailReason : undefined}
          />
        </form>
        <Box mt="sp32">
          <Button
            data-testid="add-parent-modal-save-btn"
            variant="primary"
            disabled={!requiredFieldsChanged() || !validInput()}
            onClick={handleSubmit}
          >
            {t("Save and Add Parent")}
          </Button>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default AddParentModal;
