import React, { useEffect, useState, useCallback } from "react";
import Api from "../../utils/Api";
import { Button, Box, Icon, TextInput, Modal, ModalBody, useTranslation } from "@familyzone/component-library";
import { isValidEmail } from "../../utils/Validation";
import { Guardian } from "../../types/Community";
import { useSessionStore } from "../../storez/SessionStore";

interface EditParentModalProps {
  guardian: Guardian;
  show: boolean;
  handleHide: () => void;
  handleSuccess: (guardian: Guardian) => void;
  handleFail: (message: string) => void;
}

const EditParentModal: React.FC<EditParentModalProps> = ({
  guardian,
  show,
  handleHide,
  handleSuccess,
  handleFail,
}: EditParentModalProps) => {
  const { t } = useTranslation();

  const device = useSessionStore.getState().getDevice();

  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [invalidFirstNameReason, setInvalidFirstNameReason] = useState<string | undefined>(undefined);
  const [invalidLastNameReason, setInvalidLastNameReason] = useState<string | undefined>(undefined);
  const [invalidEmailReason, setInvalidEmailReason] = useState<string | undefined>(undefined);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setFirstName(guardian.firstName);
      setMiddleName(guardian.middleName ?? "");
      setLastName(guardian.lastName);
      setEmail(guardian.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const changed = (): boolean => {
    return (
      firstName !== guardian.firstName ||
      middleName !== (guardian.middleName ?? "") ||
      lastName !== guardian.lastName ||
      email !== guardian.email
    );
  };

  const validInput: () => boolean = useCallback(() => {
    return (invalidFirstNameReason || invalidLastNameReason || invalidEmailReason) === undefined;
  }, [invalidFirstNameReason, invalidLastNameReason, invalidEmailReason]);

  function validateFirstName(firstName: string) {
    let firstname_error = undefined;
    if (firstName.length === 0) {
      firstname_error = "First name is required";
    }
    setInvalidFirstNameReason(firstname_error);
  }

  function validateEmail(email: string) {
    let email_error = undefined;
    if (email.length === 0) {
      email_error = "Email is required";
    } else if (!isValidEmail(email)) {
      email_error = "Please enter a valid email address";
    }
    setInvalidEmailReason(email_error);
  }

  function validateLastName(lastName: string) {
    let lastname_error = undefined;
    if (lastName.length === 0) {
      lastname_error = "Last name is required";
    }
    setInvalidLastNameReason(lastname_error);
  }

  function handleChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
    setFirstName(event.target.value);
    validateFirstName(event.target.value);
  }

  function handleChangeMiddleName(event: React.ChangeEvent<HTMLInputElement>) {
    setMiddleName(event.target.value);
  }

  function handleChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
    setLastName(event.target.value);
    validateLastName(event.target.value);
  }

  function handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
    validateEmail(event.target.value);
  }

  function handleSubmit() {
    if (validInput()) {
      setLoading(true);
      Api.put(
        `/config/ajax/devices/${device.id}/guardians/${guardian.id}`,
        {
          email: email,
          firstName: firstName,
          lastName: lastName,
          middleName: middleName,
        },
        function () {
          setLoading(false);

          const updatedGuardian = {
            ...guardian,
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            email: email,
          };
          handleSuccess(updatedGuardian);
        },
        function (_: unknown, code: number) {
          setLoading(false);
          if (code === 409) {
            handleFail(`The email address ${email} cannot be used. Please enter a different email.`);
          } else {
            handleFail("Sorry, we were unable to update the parent's details. Please try again later.");
          }
        }
      );
    }
  }

  function handleCloseModal() {
    setFirstName(guardian.firstName);
    setMiddleName(guardian.middleName || "");
    setLastName(guardian.lastName);
    setEmail(guardian.email);
    setInvalidFirstNameReason(undefined);
    setInvalidLastNameReason(undefined);
    setInvalidEmailReason(undefined);
    handleHide();
  }

  return (
    <Modal headerText={t("Edit Parent Details")} isOpen={show} onClose={handleCloseModal} size="sm">
      <ModalBody>
        <form className="modal-form">
          <TextInput
            label="First Name"
            placeholder=""
            name="first-name"
            value={firstName}
            onChange={handleChangeFirstName}
            isRequired={true}
            errorMessage={invalidFirstNameReason}
          />
          <TextInput label="Middle Name" placeholder="" name="middle-name" value={middleName} onChange={handleChangeMiddleName} />
          <TextInput
            label="Last Name"
            placeholder=""
            name="last-name"
            value={lastName}
            onChange={handleChangeLastName}
            isRequired={true}
            errorMessage={invalidLastNameReason}
          />
          <TextInput
            label="Email Address"
            placeholder=""
            name="email"
            value={email}
            onChange={handleChangeEmail}
            isRequired={true}
            errorMessage={invalidEmailReason}
          />
        </form>
        <Box mt="sp32">
          <Button
            variant="primary"
            mr="20px"
            leftIcon={<Icon icon="fa-save" variant="solid" color="white" data-testid="save-icon" />}
            onClick={handleSubmit}
            disabled={loading || !validInput() || !changed()}
            data-testid="edit-parent-modal-save-btn"
          >
            {t("Save Details")}
          </Button>
          <Button variant="ghost" onClick={handleCloseModal} disabled={loading} data-testid="edit-parent-modal-cancel-btn">
            {t("Cancel")}
          </Button>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default EditParentModal;
