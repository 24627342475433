import React from "react";
import { Box, Button, Modal, ModalBody, Text, useTranslation } from "@familyzone/component-library";

interface PurgeParentsModalProps {
  isOpen: boolean;
  onClose: () => void;
  purgeParents: (purgeSet: string) => void;
}
const PurgeParentsModal: React.FC<PurgeParentsModalProps> = ({ isOpen, onClose, purgeParents }: PurgeParentsModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal headerText={t("Purge All Parents")} isOpen={isOpen} size="md" variant="danger" onClose={onClose}>
      <ModalBody>
        <Text mt="sp16">{t("This will remove the community function for connected students")}</Text>
        <Text mt="sp24">{t("Please select whether you wish to delete local/imported/all parents")}</Text>
        <Box mt="sp24">
          <Button variant="danger" mr="sp16" onClick={() => purgeParents("LOCAL")}>
            {t("Purge Local Parents")}
          </Button>
          <Button variant="danger" mr="sp16" onClick={() => purgeParents("IMPORTED")}>
            {t("Purge Imported Parents")}
          </Button>
          <Button variant="danger" mr="sp16" onClick={() => purgeParents("ALL")}>
            {t("Purge All Parents")}
          </Button>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default PurgeParentsModal;
