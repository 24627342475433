import React, { useEffect, useState, useRef } from "react";
import renderAMLineChart from "../utils/Charts/AmCharts/renderAMLineChart";
import ComponentLoading from "./ComponentLoading";

export default function LineGraph(props) {
  const [graphLoaded, setGraphLoaded] = useState(false);
  const chartRef = useRef();

  useEffect(() => {
    chartRef.current = renderAMLineChart(props.data, props.container, () => {
      setGraphLoaded(true);
    });

    return () => {
      chartRef.current && chartRef.current.dispose();
      chartRef.current = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = props.data;
    }
  }, [props.data]);

  const showNoData = props.dataLoaded && props.data.length === 0;
  const showGraph = props.dataLoaded && props.data.length > 0 && graphLoaded;
  const showLoading = !props.dataLoaded || (props.data.length > 0 && !graphLoaded);

  return (
    <div className="chart-container has-centered-elements">
      {showLoading && (
        <div className="is-centered">
          <ComponentLoading />
        </div>
      )}
      {showNoData && <div className="is-centered">No data to display</div>}

      <div id={props.container} className={`chart ${!showGraph ? "loading" : ""}`} />
    </div>
  );
}
