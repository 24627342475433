import React, { useState } from "react";
import EditParentModal from "../modals/EditParentModal";
import { Link, useToast, useTranslation } from "@familyzone/component-library";
import { LinkedGuardian, isLocalGuardian } from "../../types/Community";

interface ParentDetailsProps {
  parent: LinkedGuardian;
  policyDelegationEnabled: boolean;
  disableActions: boolean;
  handleShowDeleteModal: (showModal: boolean, guardianId: string, guardianName: string, guardianEmail: string, unlink: boolean) => void;
}

const ParentDetails = ({ parent, policyDelegationEnabled, disableActions, handleShowDeleteModal }: ParentDetailsProps): JSX.Element => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState(parent.firstName || "");
  const [middleName, setMiddleName] = useState(parent.middleName || "");
  const [lastName, setLastName] = useState(parent.lastName || "");
  const [email, setEmail] = useState(parent.email || "");

  const [showEditParentModal, setShowEditParentModal] = useState(false);

  const { successToast, errorToast } = useToast();

  const saveSuccess = (parentName: string): void => {
    successToast({
      title: t("Parent details updated"),
      description: t(`The details for ${parentName} have been updated`),
      duration: 3000,
      isClosable: true,
    });
  };

  const saveFailed = (message: string): void => {
    errorToast({
      title: t("Something went wrong"),
      description: t(message),
      isClosable: true,
    });
  };

  const handleHideEditParentModal = () => {
    setShowEditParentModal(false);
  };

  const handleEditParentSuccess = (updatedGuardian: LinkedGuardian) => {
    setFirstName(updatedGuardian.firstName);
    setMiddleName(updatedGuardian.middleName || "");
    setLastName(updatedGuardian.lastName);
    setEmail(updatedGuardian.email);
    setShowEditParentModal(false);
    saveSuccess(`${updatedGuardian.firstName} ${updatedGuardian.lastName}`);
  };

  const handleEditParentFailed = (message: string) => {
    saveFailed(message);
  };

  const handleDelete = () => {
    const parentName = `${firstName} ${lastName}`;
    handleShowDeleteModal(true, parent.id, parentName, parent.email, !!parent.canBeUnlinked);
  };

  const localGuardian = isLocalGuardian(parent);

  return (
    <div data-testid="parent-details-card" className="card">
      <div className="card-content">
        <p className="card-title">Parent Details</p>
        <p>Email: {email}</p>
        <p>First name: {firstName}</p>
        <p>Middle name: {middleName}</p>
        <p>Last name: {lastName}</p>
        <p>
          Source Type:&nbsp;
          <span className="text-capitalized">{parent.sourceType.toLowerCase()}</span>
        </p>
        {policyDelegationEnabled && (
          <p>
            Parental Controls:&nbsp;
            <span className="text-capitalized">{parent.delegated ? "Accepted" : "Not Accepted"}</span>
          </p>
        )}
      </div>

      <div className="card-actions">
        <div className="left-align">
          <Link onClick={() => setShowEditParentModal(true)}>
            {localGuardian && !parent.canBeUnlinked && !disableActions && "Edit Details"}
          </Link>
          <span>{!localGuardian && !parent.canBeUnlinked && "Linked through SIS"}</span>
        </div>

        <div className="right-align">
          <Link onClick={handleDelete}>{parent.canBeUnlinked && "Disconnect Parent"}</Link>
          <Link onClick={handleDelete}>{localGuardian && !parent.canBeUnlinked && !disableActions && "Delete Parent"}</Link>
        </div>
      </div>

      <EditParentModal
        guardian={{
          id: parent.id,
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          email: email,
          sourceType: parent.sourceType,
        }}
        show={showEditParentModal}
        handleHide={handleHideEditParentModal}
        handleSuccess={handleEditParentSuccess}
        handleFail={handleEditParentFailed}
      />
    </div>
  );
};

export default ParentDetails;
