import React, { useCallback, useEffect } from "react";
import { Box, Button, InlineNotification, Text, useToast, useTranslation } from "@familyzone/component-library";
import { Flex, ListItem, OrderedList } from "@chakra-ui/layout";
import { Img } from "@chakra-ui/image";
import WindowsLogo from "../../../images/Windows_logo_-_2012.svg";
import MacLogo from "../../../images/Apple_logo_black.svg";
import ChromebookLogo from "../../../images/Google_Chrome_icon_February_2022.svg";
import SessionStore from "../../stores/SessionStore";
import { useAgentAuthStore } from "../../stores/AgentAuthStore";
import { useFeatureFlagStore } from "../../storez/FeatureFlagStore";
import { ResponseError } from "../../types/Api";

const MobileAgentDownloads: React.FC = () => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const [agentAuth, fetchAgentAuth] = useAgentAuthStore(useCallback((state) => [state.agentAuth, state.getAgentAuth] as const, []));
  const [featureFlags, getOrFetchFeatureFlags] = useFeatureFlagStore(useCallback((state) => [state.flags, state.getOrFetch] as const, []));

  useEffect(() => {
    void Promise.all([fetchAgentAuth(), getOrFetchFeatureFlags()]).catch((err: ResponseError) => {
      errorToast({
        title: t("Please try again"),
        description: t(err?.message ?? "Failed to fetch agent auth config"),
        isClosable: true,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAgentAuth, getOrFetchFeatureFlags]);

  const copyToClipboard = async (text: string) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      errorToast({
        title: t("Failed to Copy"),
        description: t("Clipboard not supported"),
        isClosable: true,
      });
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      successToast({
        title: t("Success"),
        description: "Copied!",
        isClosable: true,
      });
    } catch (error) {
      console.warn("Copy failed", error);
    }

    await navigator.clipboard.writeText(text);
  };

  const getRegion = (region: string) => {
    if (region === "us-1") {
      return "syd-1";
    }

    return region;
  };

  return (
    <Box m="sp24" width="100%">
      <Box backgroundColor="white" pb="50px" width="80%">
        <Text
          mb="sp12"
          fontSize="xxl"
          ml="sp24"
          style={{
            fontFamily: "Roboto, sans-serif",
            paddingTop: "10px",
          }}
        >
          {t("Mobile Agent Software Downloads")}
        </Text>
        <Text mb="sp12" fontSize="lg" ml="sp24">
          Agent installation and uninstallation guide:
        </Text>
        <OrderedList mb="sp24" ml="40px">
          <ListItem>Download the software. Click the below download link that corresponds to your child's laptop.</ListItem>
          <ListItem>
            Install the software. Double click on the Installer you've just downloaded and follow the prompts to install the software.
          </ListItem>
        </OrderedList>
        <Box m="sp24">
          <InlineNotification
            notificationTitle="Linewize Connect Minimum System Requirements"
            notificationDescription={
              <>
                Please check out our{" "}
                <u>
                  <a href="https://help.linewize.com/hc/en-gb/articles/10051669060508" target="_blank" rel="noopener noreferrer">
                    knowledge base article
                  </a>
                </u>{" "}
                for further information.
              </>
            }
            status="info"
          />
        </Box>
        <Flex justifyContent="space-around" flexDirection="row">
          <Box>
            <Img maxW="88px" maxH="88px" src={WindowsLogo} />
            <Button
              mt="sp12"
              variant="primary"
              display="block"
              onClick={() => window.open("https://qoria-mza-installers.linewize.net/Connect%20Installer.msi", "_blank")}
            >
              Download for Windows (.msi)
              <br />
              For school IT admins
            </Button>
            <Button
              mt="sp12"
              variant="primary"
              display="block"
              onClick={() => window.open("https://qoria-mza-installers.linewize.net/Connect%20Installer.exe", "_blank")}
            >
              Download for Windows
            </Button>
          </Box>
          <Box>
            <Img maxW="88px" maxH="88px" src={MacLogo} />
            <Button
              mt="sp12"
              variant="primary"
              display="block"
              onClick={() => window.open("https://qoria-mza-installers.linewize.net/Connect%20Installer.pkg", "_blank")}
            >
              Download for Mac (.pkg)
              <br />
              For school IT admins
            </Button>
            <Button
              mt="sp12"
              variant="primary"
              display="block"
              onClick={() => window.open("https://qoria-mza-installers.linewize.net/Connect%20Installer.app.zip", "_blank")}
            >
              Download for Mac
            </Button>
          </Box>
          <Box>
            <Img maxW="88px" maxH="88px" src={ChromebookLogo} />
            {featureFlags && Object.keys(featureFlags).length > 0 && (
              <>
                <Button
                  mt="sp12"
                  variant="primary"
                  display="block"
                  // eslint-disable-next-line
                  onClick={() => copyToClipboard(process.env.REACT_APP_LINEWIZE_CHROME_EXTENSION_ID as string)}
                >
                  Copy Extension ID
                </Button>

                <Button
                  mt="sp12"
                  variant="primary"
                  display="block"
                  // eslint-disable-next-line
                  onClick={() => copyToClipboard(`https://download.qoria.com/browser/${SessionStore.getSelectedDevice()}`)}
                >
                  Copy Download URL
                </Button>

                <Button
                  mt="sp12"
                  variant="primary"
                  display="block"
                  // eslint-disable-next-line
                  onClick={() =>
                    copyToClipboard(
                      JSON.stringify({
                        configuration: {
                          Value: {
                            // eslint-disable-next-line
                            region: getRegion(SessionStore.getSelectedRegion()),
                            // eslint-disable-next-line
                            applianceId: SessionStore.getSelectedDevice(),
                            preSharedKey: agentAuth.key,
                          },
                        },
                      })
                    )
                  }
                >
                  Copy Managed Config
                </Button>
              </>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default MobileAgentDownloads;
