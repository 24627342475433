import Api from "../../utils/Api";
import { Directive, FilteringRule, TimePeriod, SafeSearchRule, Keyword, Signature, Verdict, BypassCode } from "./types";
import { MacObject, IpObject, Fingerprint, SecurityGroup, KeywordObject, WebsiteObject } from "../../modules/criteria/criteriaTypes";

export const createPolicy = async (content_mod?: string[]): Promise<void> => {
  try {
    await Api.putAsync("/surfwize/device/ajax/webfiltering", { content_mod: content_mod });
  } catch (err) {
    throw err;
  }
};

export const modifyPolicy = async (rule: FilteringRule): Promise<void> => {
  try {
    await Api.postAsync("/surfwize/device/ajax/webfiltering", rule);
  } catch (err) {
    throw err;
  }
};

export const sortPolicies = async (newRules: FilteringRule[]): Promise<void> => {
  try {
    const contentModPolicies = await getPolicies(true);
    const stringIds = [...newRules, ...contentModPolicies].map((r) => r.id).join(",");
    await Api.postAsync("/surfwize/device/ajax/webfiltering/reorder", { order: stringIds });
  } catch (err) {
    throw err;
  }
};

export const deletePolicy = async (id: string): Promise<void> => {
  try {
    await Api.deleteAsync("/surfwize/device/ajax/webfiltering", { id });
  } catch (err) {
    throw err;
  }
};

export const getPolicies = async (showContentModifications: boolean): Promise<FilteringRule[]> => {
  try {
    const result = (await Api.getAsync("/surfwize/device/ajax/webfiltering")) as { data: FilteringRule[] };
    // Remove any content mod rules from the ruleset before showing them
    const filteringRules: FilteringRule[] = result.data.filter((d) =>
      showContentModifications ? d.content_mod !== undefined : d.content_mod === undefined
    );
    return filteringRules;
  } catch (err) {
    throw err;
  }
};

export const getDirectives = async (): Promise<Directive[]> => {
  try {
    const result = (await Api.getAsync("/surfwize/ajax/directives")) as { directives: Directive[] };
    return result?.directives;
  } catch (err) {
    throw err;
  }
};

export type GetObjectsResponse = MacObject | IpObject | KeywordObject | WebsiteObject;

export const getObjects = async (): Promise<GetObjectsResponse[]> => {
  const result = (await Api.getAsync("/config/device/ajax/aliases")) as { data: GetObjectsResponse[] };
  return result.data;
};

export const getFingerprints = async (): Promise<Fingerprint[]> => {
  const result = (await Api.getAsync("/config/ajax/fingerprints")) as { fingerprints: Fingerprint[] };
  return result.fingerprints;
};

export const getTimePeriods = async (): Promise<TimePeriod[]> => {
  const result = (await Api.getAsync("/config/device/ajax/periods")) as { data: TimePeriod[] };
  return result.data;
};

export const getSecurityGroups = async (): Promise<SecurityGroup[]> => {
  const result = (await Api.getAsync("/config/device/ajax/securitygroups")) as { data: SecurityGroup[] };
  return result.data;
};

export const getVerdict = async (username: string, url: string): Promise<Verdict> => {
  return Api.getAsync(`/surfwize/verdict?url=${encodeURIComponent(url)}&username=${encodeURIComponent(username)}`) as Promise<Verdict>;
};

export const getSafeSearchRules = async (): Promise<SafeSearchRule[]> => {
  const result = (await Api.getAsync("/surfwize/device/ajax/safesearch")) as { data: SafeSearchRule[] };
  return result.data;
};

export const createSafeSearchRule = async (): Promise<void> => {
  await Api.putAsync("/surfwize/device/ajax/safesearch");
};

export const deleteSafeSearchRule = async (id: string): Promise<void> => {
  await Api.deleteAsync("/surfwize/device/ajax/safesearch", { id });
};

export const modifySafeSearchRule = async (rule: SafeSearchRule): Promise<void> => {
  await Api.postAsync("/surfwize/device/ajax/safesearch", rule);
};

export const sortSafeSearchRules = async (newRules: SafeSearchRule[]): Promise<void> => {
  const stringIds = newRules.map((r) => r.id).join(",");
  await Api.postAsync("/surfwize/device/ajax/safesearch/reorder", { order: stringIds });
};

export const getSignaturesAndKeywords = async (): Promise<{ keywords: Keyword[]; signatures: Signature[] }> => {
  const result = (await Api.getAsync("/config/ajax/signatures")) as { keywords: Keyword[]; signatures: Signature[] };
  return result;
};

export type QueryDomainSignatureResponse =
  | {
      category: string;
      dependents: string[];
      noise: boolean;
      signature: string;
      subCategory: string;
    }
  | {
      error: string;
      noise: boolean;
    };

export const queryDomainSignature = async (url: string): Promise<QueryDomainSignatureResponse> => {
  const result = (await Api.getAsync(`/surfwize/device/ajax/signature/domain/${encodeURIComponent(url)}`)) as QueryDomainSignatureResponse;
  return result;
};

export const getBypassCodes = async (): Promise<BypassCode[]> => {
  const result = (await Api.getAsync("/surfwize/device/ajax/bypass/available")) as { result: BypassCode[] };
  return result.result;
};

export type GetUserInfoResponse = {
  admin: boolean;
  appindex_admin: boolean;
  customerid: string;
  demo_account: boolean;
  email: string;
  enabled: boolean;
  msp: string;
  password: null;
  phone_number: string;
  support_admin: boolean;
  two_factor: boolean;
};

export const getUserInfo = async (): Promise<GetUserInfoResponse> => {
  const result = (await Api.getAsync("/settings/account")) as { result: GetUserInfoResponse };
  return result.result;
};

export const updateBypassCode = async (code: BypassCode): Promise<void> => {
  await Api.postAsync("/surfwize/device/ajax/bypass/update", code);
};

export type BypassCodeWithoutId = Omit<BypassCode, "id">;

export const createBypassCode = async (code: BypassCodeWithoutId): Promise<void> => {
  await Api.postAsync("/surfwize/device/ajax/bypass/add", code);
};

export const generateBypassCode = async (): Promise<string> => {
  const result = (await Api.getAsync("/surfwize/device/ajax/bypass/generate")) as { result: string };
  return result.result;
};

export type ActiveBypassCode = {
  activation_time: number;
  available_bypass_id: string;
  cid: string | null;
  code: string;
  expiry_time: number;
  ip: string;
  mac: string;
  username: string;
};

export const getBypassActive = async (): Promise<ActiveBypassCode[]> => {
  const result = (await Api.getAsync("/surfwize/device/ajax/bypass/active")) as { result: ActiveBypassCode[] };
  return result.result;
};

export type EmailReport = {
  daily_filtering_alerts_digest: boolean;
  deviceid: string;
  disable_rate_limiting: boolean;
  email: string;
  filtering_alerts: boolean;
  groups: string[];
  recipient_id: number;
  red_flags: boolean;
  selected_red_flags: string[];
  state_deleting?: boolean;
  state_editing?: boolean;
  teacher_added_user: boolean;
  user_downvotes: boolean;
  username: string;
  weekly_report: boolean;
  weekly_welfare_report: boolean;
};

export const getReports = async (): Promise<EmailReport[]> => {
  const result = (await Api.getAsync("/surfwize/ajax/reports")) as { data: EmailReport[] };
  return result.data;
};

export const deleteReport = async (id: number): Promise<void> => {
  await Api.deleteAsync("/surfwize/ajax/reports", { id });
};

export const createReport = async (): Promise<void> => {
  await Api.putAsync("/surfwize/ajax/reports");
};

export type ReportTestResponse = {
  sendingEmail: boolean;
  notificationMessageType: string;
  notificationMessage: string;
};

export const testReport = async (report: EmailReport): Promise<ReportTestResponse> => {
  const result = (await Api.postAsync("/surfwize/ajax/reports/test", report)) as { data: ReportTestResponse };
  return result.data;
};

export const editReport = async (report: EmailReport): Promise<void> => {
  await Api.postAsync("/surfwize/ajax/reports", report);
};

export type UrlSignatureSearchResponse = {
  matching_rules: FilteringRule[];
  results__signatures: Signature[];
};

export const urlSignatureSearch = async (url: string): Promise<QueryDomainSignatureResponse> => {
  const results: QueryDomainSignatureResponse = await queryDomainSignature(url);
  return results;
};

export const urlPolicySearch = async (url: string): Promise<UrlSignatureSearchResponse> => {
  const result = (await Api.getAsync(`/surfwize/device/ajax/signature/search/${encodeURIComponent(url)}`)) as UrlSignatureSearchResponse;
  return result;
};

export const reviewDomain = async (domain: string): Promise<unknown> => {
  const result = (await Api.getAsync(`/surfwize/review/${encodeURIComponent(domain)}`)) as unknown;
  return result;
};
