import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { formatUnixTimestamp } from "../../DateTimeUtil";
import { formatToMB } from "../../StringUtil";

const addLicense = () => {
  am4core.addLicense("CH262668106");
};

const addStyling = (chart, series) => {
  chart.padding(20, 30, 20, 20);
  chart.fontFamily = "Lato";
  chart.fontSize = "11";

  series.fontSize = 10;
  series.stroke = am4core.color("#79C5E0");
  series.fill = am4core.color("#9ed4e8");
  series.fillOpacity = 1;
};

const addScrollbar = (chart) => {
  chart.scrollbarX = new am4core.Scrollbar();
  chart.scrollbarX.parent = chart.bottomAxesContainer;
};

const addTooltip = (chart) => {
  chart.cursor = new am4charts.XYCursor();
};

/*
AM Charts adds unncessary empty space in the beginning and end of charts
* This helps to start the axis from the minimum value
* and end at the max
* Source: https://codepen.io/team/amcharts/pen/JjGmWOO
*/
const showExactMinMaxValues = (axis) => {
  axis.strictMinMax = true;

  let minRangeX = axis.axisRanges.create();
  minRangeX.minPosition = 0.2;

  let maxRangeX = axis.axisRanges.create();
  maxRangeX.maxPosition = 0.2;

  /* If a scrollbar has been added to the axis,
   *  then change the mix/max value lavels on scrollbar change
   */
  const updateXLabels = () => {
    minRangeX.value = axis.min + axis.start * (axis.max - axis.min);
    minRangeX.label.text = minRangeX.value;

    maxRangeX.value = axis.min + axis.end * (axis.max - axis.min);
    maxRangeX.label.text = axis.value;
  };

  axis.events.on("startendchanged", updateXLabels);
  axis.events.on("extremeschanged", updateXLabels);
};

const formatAxis = (axis, axisFormatter) => {
  if (axisFormatter) {
    axis.renderer.labels.template.adapter.add("textOutput", function (text, target) {
      const value = target.dataItem.value;
      return value ? axisFormatter(value) : "";
    });

    axis.adapter.add("getTooltipText", (tooltipText) => {
      const value = parseInt(tooltipText.replace(/,/g, ""));
      return value ? axisFormatter(value) : "";
    });
  }
};

const renderAMLineChart = (seriesData, chartContainerId, readyFunction) => {
  addLicense();
  am4core.useTheme(am4themes_animated);

  let chart = am4core.create(chartContainerId, am4charts.XYChart);
  chart.data = seriesData;

  let valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
  let valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());

  valueAxisY.renderer.minGridDistance = 50;

  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueX = "time";
  series.dataFields.valueY = "value";

  showExactMinMaxValues(valueAxisX);

  formatAxis(valueAxisX, formatUnixTimestamp);
  formatAxis(valueAxisY, formatToMB);

  addStyling(chart, series);
  addScrollbar(chart);
  addTooltip(chart);

  chart.events.once("ready", (event) => readyFunction());

  return chart;
};

export default renderAMLineChart;
