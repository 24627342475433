import LogRocket from "logrocket";
import { v4 as uuidV4 } from "uuid";
import { parseEnvVarToStringArray } from "./envUtils";

export function initLogRocket(applianceId: string, username: string): void {
  // Return early if applianceId or enabledAppliances are blank as performance optimisation
  // so we're not doing an expensive env var parse or include-check if not necessary.

  if (!applianceId) return;
  if (!username) return;

  const disabledUsers = parseEnvVarToStringArray(process.env.REACT_APP_LOGROCKET_DISABLED_USERS);
  if (disabledUsers.includes(username)) return;

  const excludedAppliances = parseEnvVarToStringArray(process.env.REACT_APP_LOGROCKET_EXCLUDED_APPLIANCE_IDS);

  // Don't initalise Log Rocket for demo and testing appliances
  if (excludedAppliances.includes(applianceId)) return;

  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID || "");

  LogRocket.identify(uuidV4(), {
    email: username,
    schoolManagerDeviceID: applianceId,
  });
}
