import { Box, Button, Flex, InlineNotification, Modal, useToast } from "@familyzone/component-library";
import $ from "jquery";
import React, { useState } from "react";
import "../../../../../css/Classrooms.css";
import ImportClassroomFileList from "./ImportClassroomFileList";
import ImportScheduleFileList from "./ImportScheduleFileList";
import { batchCreateClassrooms } from "../../../../utils/api/Classrooms";
import { useSetState, useMount } from "react-use";
import { importCSVSchedules } from "./CSVImportHelpers";

export const ClassroomsImportCSVModal = (props) => {
  const { successToast, infoToast, errorToast } = useToast();

  const [state, setState] = useSetState({
    dropHover: false,
    uniqueClassroomEntries: {},
    classroomFiles: new Set(),
    scheduleObjects: new Set(),
    scheduleFiles: new Set(),
    importing: false,
  });

  const [dragCounter, setDragCounter] = useState(0);

  useMount(() => {
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
        handleDragLeave();
      },
      false
    );
    window.addEventListener("dragleave", handleDragLeave);
  });

  const handleHide = () => {
    setState({
      uniqueClassroomEntries: {},
      classroomFiles: new Set(),
      scheduleObjects: new Set(),
      scheduleFiles: new Set(),
    });
    props.handleClose();
  };

  const handle_Submit = () => {
    setState({ importing: true });
    if (Object.keys(state.uniqueClassroomEntries).length > 0) {
      let classrooms = JSON.stringify(state.uniqueClassroomEntries);
      batchCreateClassrooms(classrooms)
        .then(() => {
          handleHide();
          successToast({ title: "Success!", description: "We've received the request and it has been sent for processing." });
        })
        .catch(() => {
          errorToast({
            title: "Error",
            description:
              "We're not able to process your file(s) at this time. Please ensure your file(s) are formatted correctly. See the example format on the form.",
          });
        })
        .finally(() => {
          setState({ importing: false });
        });
    } else if (state.scheduleFiles.size > 0) {
      importCSVSchedules(state.scheduleObjects)
        .then((response) => {
          handleHide();
          infoToast({
            title: "Import complete",
            description: `Imported ${response.importedPeriodsCount} schedule periods across ${response.importedClassroomsCount} classrooms.`,
          });
        })
        .catch((error) => {
          console.error(error);
          errorToast({
            title: "Error",
            description:
              "We're not able to process your file(s) at this time. Please ensure your file(s) are formatted correctly. See the example format on the form.",
          });
        })
        .finally(() => {
          setState({ importing: false });
        });
    } else {
      setTimeout(() => {
        setState({ importing: false });
        handleHide();
      }, 500);
    }
  };

  const renderActions = () => {
    if (state.importing) {
      return (
        <Button onClick={handle_Submit} variant="primary" disabled={true}>
          Importing
        </Button>
      );
    } else {
      return (
        <Button onClick={handle_Submit} variant="primary" data-testid="import-button">
          Import
        </Button>
      );
    }
  };

  const handle_updateClassroomCsvObject = (classroomCsvObjects) => {
    setState({ uniqueClassroomEntries: updateUniqueClassroomEntryArray(classroomCsvObjects) });
  };

  const handle_updateScheduleCsvObject = (scheduleCsvObjects) => {
    setState({ scheduleObjects: scheduleCsvObjects });
  };

  const handle_deleteClassroomCsvObject = (csvObject) => {
    let updatedFiles = state.classroomFiles;
    updatedFiles.delete(csvObject.getFile());
    setState({ classroomFiles: updatedFiles });
  };

  const handle_deleteScheduleCsvObject = (csvObject) => {
    let updatedFiles = state.scheduleFiles;
    updatedFiles.delete(csvObject.getFile());
    setState({ scheduleFiles: updatedFiles });
  };

  const handleDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "copy"; // Explicitly show this is a copy.
  };

  const handleDragEnter = (evt) => {
    setDragCounter(dragCounter + 1);
    setState({ dropHover: true });
  };

  const handleDragLeave = (evt) => {
    setDragCounter(dragCounter - 1);
    if (dragCounter === 0) {
      setState({ dropHover: false });
    }
  };

  const handleClassroomDrop = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setDragCounter(0);
    setState({ dropHover: false });
    setState({
      classroomFiles: addFiles(evt.dataTransfer.files, state.classroomFiles),
    });
  };

  const handleScheduleDrop = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setDragCounter(0);
    setState({ dropHover: false });
    setState({
      scheduleFiles: addFiles(evt.dataTransfer.files, state.scheduleFiles),
    });
  };

  const handleClassroomFileSelect = (evt) => {
    setState({
      classroomFiles: addFiles(evt.target.files, state.classroomFiles),
    });
  };

  const handleScheduleFileSelect = (evt) => {
    setState({
      scheduleFiles: addFiles(evt.target.files, state.scheduleFiles),
    });
  };

  function addFiles(files, currentFileList) {
    let validFiles = [];
    for (let file of files) {
      if (file.type) {
        if (
          file.type === "text/csv" ||
          file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel"
        ) {
          validFiles.push(file);
        }
      } else {
        // Windows 10 and Windows 7 sometimes return an empty string for the file type,
        // so we can check the extension as to whether the file is a csv or not
        if (file.name.endsWith(".csv")) {
          validFiles.push(file);
        }
      }
    }
    let updatedSet = currentFileList;
    for (let file of validFiles) {
      if (
        Array.from(updatedSet).filter(
          (innerFile) => innerFile.name === file.name && innerFile.size === file.size && innerFile.lastModified === file.lastModified
        ).length === 0
      ) {
        updatedSet.add(file);
      }
    }
    return updatedSet;
  }

  function updateUniqueClassroomEntryArray(classroomCsvObjects) {
    let ret = {};
    for (let csvObj of classroomCsvObjects) {
      let entries = csvObj.entries;
      for (let classroom in entries) {
        let users = entries[classroom];
        if (!ret[classroom]) {
          ret[classroom] = {};
        }

        for (let username in users) {
          ret[classroom][username] = users[username];
        }
      }
    }
    return ret;
  }

  if (props.visible) {
    return (
      <Modal headerText={"Import CSV"} onClose={handleHide} isOpen={props.visible} size="md">
        <form className="mui-form">
          <div style={{ display: state.scheduleFiles.size === 0 ? "block" : "none" }}>
            <div
              id="drop_zone_1"
              style={{ visibility: state.dropHover ? "visible" : "hidden" }}
              className={state.classroomFiles.size > 0 ? "full_size" : ""}
            >
              <div className={"dropBorder"}>
                <div
                  className={"dropContent"}
                  onDrop={handleClassroomDrop}
                  onDragEnter={() => {
                    $("#drop_zone_1").addClass("drag_over");
                  }}
                  onDragOver={() => {
                    $("#drop_zone_1").addClass("drag_over");
                  }}
                  onDragLeave={() => {
                    $("#drop_zone_1").removeClass("drag_over");
                  }}
                >
                  <div className={"dropText"}>Upload Classrooms</div>
                  <i className="fa fa-plus" />
                </div>
              </div>
            </div>
            <div className="mui-textfield" id={"file_picker_1"}>
              <input
                type="file"
                id="files_1"
                data-testid="files_1"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleClassroomFileSelect}
                multiple
              />
              <Button variant="primary" data-testid="upload-classrooms-button">
                <label className="importLabel" htmlFor="files_1">
                  Upload Classrooms
                </label>
              </Button>
              <InlineNotification
                mt="16px"
                status="info"
                notificationTitle={"Expected Classrooms CSV Format"}
                notificationDescription={
                  <Box>
                    <div>Columns: user, class, is_owner</div>
                    <div>Example:</div>
                    <div>oscar.maccay, classroom1, true</div>
                    <div>steve.johnson, classroom1, false</div>
                  </Box>
                }
              />
              <ImportClassroomFileList
                files={state.classroomFiles}
                updateCsvObjectCallback={handle_updateClassroomCsvObject}
                deleteCsvObjectCallback={handle_deleteClassroomCsvObject}
              />
            </div>
          </div>
          <div style={{ display: state.classroomFiles.size === 0 ? "block" : "none" }}>
            <div
              id="drop_zone_2"
              style={{ visibility: state.dropHover ? "visible" : "hidden" }}
              className={state.scheduleFiles.size > 0 ? "full_size" : ""}
            >
              <div className={"dropBorder"}>
                <div
                  className={"dropContent"}
                  onDrop={handleScheduleDrop}
                  onDragEnter={() => {
                    $("#drop_zone_2").addClass("drag_over");
                  }}
                  onDragOver={() => {
                    $("#drop_zone_2").addClass("drag_over");
                  }}
                  onDragLeave={() => {
                    $("#drop_zone_2").removeClass("drag_over");
                  }}
                >
                  <div className={"dropText"}>Upload Classroom Schedules</div>
                  <i className="fa fa-plus" />
                </div>
              </div>
            </div>
            <div className="mui-textfield" id={"file_picker_2"}>
              <input
                type="file"
                id="files_2"
                data-testid="files_2"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleScheduleFileSelect}
                multiple
              />
              <Button variant="primary" data-testid="upload-schedules-button">
                <label className="importLabel" htmlFor="files_2">
                  Upload Classroom Schedules
                </label>
              </Button>
              <InlineNotification
                mt="16px"
                status="info"
                notificationTitle={"Expected Classroom Schedules CSV Format"}
                notificationDescription={
                  <Box>
                    <div>Schedules can not be added unless the class has been started and ended in Classwize.</div>
                    <div>Column titles: class, day, sched_start, sched_end</div>
                    <div>Valid days: mon, tue, wed, thur, fri, sat, sun.</div>
                    <div>Valid schedule times: 0000-2345 (4-digit times in 15 minute increments).</div>
                    <div>Example:</div>
                    <div>classroom1, mon, 0815, 0945</div>
                    <div>classroom1, mon, 1115, 1200</div>
                  </Box>
                }
              />

              <ImportScheduleFileList
                files={state.scheduleFiles}
                updateCsvObjectCallback={handle_updateScheduleCsvObject}
                deleteCsvObjectCallback={handle_deleteScheduleCsvObject}
              />
            </div>
          </div>
        </form>
        {(state.classroomFiles.size > 0 || state.scheduleFiles.size > 0) && <Flex width="100%">{renderActions()}</Flex>}
      </Modal>
    );
  }
  return <div />;
};
