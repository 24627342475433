import { ImportSchedulesRequest, ImportSchedulesResponse } from "../../../../types/Classrooms";
import { importSchedules } from "../../../../utils/api/Policies";
import { ScheduleCSVObject } from "./ScheduleCSVObject";

export const processSchedulesToImport = (csvObjects: Set<unknown>): ImportSchedulesRequest => {
  const schedulesToImport: ImportSchedulesRequest = { schedules: [] };

  for (const csvObject of csvObjects) {
    if (!isScheduleCSVObject(csvObject)) {
      console.error("Invalid schedule CSV object", csvObject);
      continue;
    }

    csvObject.entries.forEach((entry) => {
      schedulesToImport.schedules.push({
        groupName: entry.classroom,
        day: entry.day,
        startTime: entry.sched_start,
        endTime: entry.sched_end,
      });
    });
  }

  return schedulesToImport;
};

export const importCSVSchedules = async (csvObjects: Set<unknown>): Promise<ImportSchedulesResponse> => {
  const schedulesToImport = processSchedulesToImport(csvObjects);
  const response = await importSchedules(schedulesToImport);
  // Log to console because the old import did so - just in case tech-savvy users were using this information
  console.log(`Imported ${response.importedPeriodsCount} periods across ${response.importedClassroomsCount} classrooms`);
  response.errors.forEach((error) => console.log(error));
  return response;
};

const isScheduleCSVObject = (possibleScheduleCSVObject: unknown): possibleScheduleCSVObject is ScheduleCSVObject => {
  return (
    typeof possibleScheduleCSVObject === "object" &&
    possibleScheduleCSVObject !== null &&
    "entries" in possibleScheduleCSVObject &&
    "unique_classrooms" in possibleScheduleCSVObject &&
    "unique_periods" in possibleScheduleCSVObject
  );
};
