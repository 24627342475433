import React from "react";
import { Box, Select, Text, useTranslation } from "@familyzone/component-library";
import { FilteringSourceCriteria } from "../criteriaTypes";
import { assignType } from "./helpers";
import { Item } from "@familyzone/component-library/dist/components/Select";

export interface GenericSingleSelectCriteriaProps {
  criteria: FilteringSourceCriteria;
  namedValue: string;
  options: Item[];
  readableName: string;
  onChange: (criteria: FilteringSourceCriteria) => void;
}

const GenericSingleSelectCriteria: React.FC<GenericSingleSelectCriteriaProps> = ({ criteria, namedValue, options, onChange }) => {
  const { t } = useTranslation();
  const [existingChoice, setExistingChoice] = React.useState<string>("");

  const handleChange = (item: Item | null) => {
    if (!item) return;
    const value = assignType(item.value);
    onChange({
      ...criteria,
      conditions: { ...criteria.conditions, [namedValue]: value },
    });
  };

  // Until the selectedItem works on initial render, we'll use this to set the existing choice text.
  React.useMemo(() => {
    if (criteria?.conditions && !Array.isArray(criteria.conditions)) {
      setExistingChoice(String(criteria.conditions[namedValue]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-testid={namedValue + "-id"}>
      <Select items={options} isFiltered onChange={handleChange} id={namedValue} />
      {existingChoice && <Text color="neutrals.400">{t(`Current selection: ${existingChoice}. Choose another option to change.`)}</Text>}
    </Box>
  );
};

export default GenericSingleSelectCriteria;
