import React from "react";
import PropTypes from "prop-types";
import Api from "../utils/Api";

import SessionActions from "../actions/SessionActions";
import SessionStore from "../stores/SessionStore";

export default class LoginGoogle extends React.Component {
  componentDidMount() {
    SessionStore.listen(this.onChange);
  }

  componentWillUnmount() {
    SessionStore.unlisten(this.onChange);
  }

  onChange = () => {
    if (SessionStore.isAuthenticated()) {
      this.context.router.push("/");
    }

    if (SessionStore.failedLogin()) {
      this.context.router.push("/");
    }
  };

  render() {
    Api.unauthenticated_post(
      "/login/google/callback?code=" + this.props.location.query.code,
      {},
      function (data) {
        SessionStore.resetLoginFailure();
        SessionActions.authenticateSuccess(data);
      },
      function (error) {
        SessionActions.authenticateFailure(error);
      }
    );

    return <div></div>;
  }
}

LoginGoogle.contextTypes = {
  router: PropTypes.object.isRequired,
};
