import {
  Box,
  Button,
  ButtonTd,
  Flex,
  Link,
  SearchBox,
  Table,
  TbodyInfiniteScroll,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  useTranslation,
} from "@familyzone/component-library";
import { toInteger } from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { DisconnectEvent, DisconnectEventPlaceholder, DisconnectType, Guardian, GuardianPlaceholder, Student } from "../../types/Community";
import Api from "../../utils/Api";
import { zIndices } from "../../utils/ZIndexUtil";
import ParentDetailsModal from "../modals/ParentDetailsModal";
import StudentDisconnectModal from "../modals/StudentDisconnectModal";
import PurgeParentsModal from "../modals/PurgeParentsModal";
import CardBasedPage from "../templates/CardBasedPage";
import EditParentModal from "../modals/EditParentModal";
import { useGuardianStore } from "../../storez/GuardianStore";
import { CommunityConfigStore, useCommunityConfigStore } from "../../storez/CommunityConfigStore";
import ExportParentsModal from "../modals/ExportParentsModal";
import { ResponseError } from "../../types/Api";
import { useSessionStore } from "../../storez/SessionStore";

const ParentManagement: React.FC = () => {
  const rowRef = useRef<HTMLTableRowElement>(null);

  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const showSuccessToast = (options: { title: string; description: string }): void => {
    successToast({ ...options, isClosable: true });
  };

  const showErrorToast = (options: { title: string; description: string }): void => {
    errorToast({ ...options, isClosable: true });
  };

  const showFetchErrorToast = (): void => {
    showErrorToast({ title: t("Please try again"), description: t("Failed to load guardians") });
  };

  const title = t("Parent Management");
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Community"), url: "/config/device/community", isActive: false },
    { title: t("Parent Management"), isActive: true },
  ];

  const device = useSessionStore.getState().getDevice();
  const isSupportAdmin = useSessionStore.getState().isSupportAdmin();

  const pageSize = useRef<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [exported, setExported] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [searchTimeout, setSearchTimeout] = useState<ReturnType<typeof setTimeout>>();

  const [selectedState, setSelectedState] = useState<{ guardian: Guardian; students?: Student[] }>();
  const [editState, setEditState] = useState<{ guardian: Guardian }>();
  const [disconnectState, setDisconnectState] = useState<DisconnectEvent>();
  const [showPurgeParentsModal, setShowPurgeParentsModal] = useState<boolean>(false);
  const [showExportParentsModal, setShowExportParentsModal] = useState<boolean>(false);

  const [guardians, hasNextPage, searchGuardians, downloadAllGuardians, setGuardian, resetGuardianStore] = useGuardianStore(
    useCallback(
      (state) => [state.guardians, state.hasNextPage, state.search, state.downloadAllGuardians, state.setGuardian, state.reset] as const,
      []
    )
  );

  const [storedCommunityConfig, fetchCommunityConfig] = useCommunityConfigStore(
    useCallback((state: CommunityConfigStore) => [state.config, state.fetch] as const, [])
  );

  useEffect(() => {
    void fetchCommunityConfig();
  }, [fetchCommunityConfig]);

  useEffect(() => {
    const pageHeight = window.innerHeight;
    const rowHeight = Math.max(rowRef?.current?.offsetHeight ?? 40, 40);
    pageSize.current = toInteger(pageHeight / rowHeight);

    void searchGuardians({ term: "", page: 0, details: true, pageSize: pageSize.current }).then(() => {
      setLoading(false);
    }, showFetchErrorToast);

    return () => resetGuardianStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchGuardians]);

  const executeSearch = (options: { term: string; page: number }): void => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        setLoading(true);
        searchGuardians({ ...options, details: true, pageSize: pageSize.current }).then(() => {
          setLoading(false);
        }, showFetchErrorToast);
      }, 1000)
    );
  };

  const executeDownload = (): void => {
    setLoading(true);
    setExported(false);

    void downloadAllGuardians().then(
      () => {
        setLoading(false);
        setExported(true);
      },
      (err: ResponseError) => {
        showErrorToast({ title: t("Please try again"), description: t(err.message) });
        setLoading(false);
        setExported(false);
      }
    );
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.target.value);
    setPageNumber(0);
    executeSearch({ term: e.target.value, page: 0 });
  };

  const handleSearchClear = (): void => {
    setSearchTerm("");
    setPageNumber(0);
    executeSearch({ term: "", page: 0 });
  };

  const handleLoadMore = (): void => {
    if (loading || !hasNextPage) {
      return;
    }

    setLoading(true);
    setPageNumber(pageNumber + 1);
    executeSearch({ term: searchTerm, page: pageNumber + 1 });
  };

  const fetchStudents = (guardian: Guardian): void => {
    Api.get(
      `/config/ajax/guardians/${guardian.id}/students`,
      (result: { students: Student[] }) => {
        // refresh 'Parent Details' modal with students
        setSelectedState({ guardian, students: result.students });
      },
      () => {
        showErrorToast({
          title: "Please try again",
          description: "An unexpected error occurred while loading parent details.",
        });
      }
    );
  };

  const unclaimStudent = (guardian: Guardian, student: Student): void => {
    Api.delete(
      `/config/ajax/devices/${device.id}/users/${student.username}/guardians/${guardian.email}/claim`,
      {},
      () => {
        // propagate guardian changes
        if (guardian.claimCount) {
          guardian.claimCount -= 1;
        }
        setGuardian(guardian);

        // propagate student changes
        setSelectedState((selected) => {
          if (!selected?.students) {
            throw Error("Students should be set");
          }
          const students = [...selected.students];
          for (const s of students) {
            if (s.id === student.id) {
              s.claimed = false;
              break;
            }
          }
          return { guardian, students };
        });

        // close confirmation modal
        setDisconnectState(undefined);

        showSuccessToast({
          title: t("Disconnect successful"),
          description: `${student.firstName} ${student.lastName} 
          ${t("has been successfully disconnected from")}
          ${guardian.firstName} ${guardian.lastName}`,
        });
      },
      () => {
        setDisconnectState(undefined);
        showErrorToast({
          title: "Please try again",
          description: `An unexpected error occurred while disconnecting ${student.firstName}
          ${student.lastName} from ${guardian.firstName} ${guardian.lastName}`,
        });
      }
    );
  };

  const unlinkStudent = (guardian: Guardian, student: Student): void => {
    Api.delete(
      `/config/ajax/devices/${device.id}/users/${student.username}/guardians/${guardian.id}`,
      {},
      () => {
        // propagates guardian changes
        if (guardian.linkCount) {
          guardian.linkCount -= 1;
        }
        setGuardian(guardian);

        // propagate student changes
        setSelectedState((selected) => {
          if (!selected?.students) {
            throw Error("Students should be set");
          }
          return { guardian, students: selected.students.filter((s) => s.id !== student.id) };
        });

        // close confirmation modal
        setDisconnectState(undefined);

        showSuccessToast({
          title: t("Link removed"),
          description: `${t("The link between")} ${student.firstName} ${student.lastName} ${t("and")}
          ${guardian.firstName} ${guardian.lastName} ${t("has been successfully removed")}`,
        });
      },
      () => {
        setDisconnectState(undefined);
        showErrorToast({
          title: "Please try again",
          description: `An unexpected error occurred while removing the link between ${student.firstName}
          ${student.lastName} and ${guardian.firstName} ${guardian.lastName}`,
        });
      }
    );
  };

  const handleOpenParentDetailsModal = (guardian: Guardian): void => {
    // open modal in 'loading' state with spinner visible
    setSelectedState({ guardian });

    // fetch students (on success updates modal to 'ready' state)
    fetchStudents(guardian);
  };

  const handleDisconnectStudent = (event: DisconnectEvent): void => {
    // open confirmation modal
    setDisconnectState(event);
  };

  const handleCloseParentDetailsModal = (): void => {
    setDisconnectState(undefined);
    setSelectedState(undefined);
  };

  const handleCloseStudentDisconnectModal = (): void => {
    // close confirmation modal
    setDisconnectState(undefined);
  };

  const handleConfirmStudentDisconnect = (): void => {
    if (disconnectState?.type === DisconnectType.Unclaim) {
      unclaimStudent(disconnectState.guardian, disconnectState.student);
    } else if (disconnectState?.type === DisconnectType.Unlink) {
      unlinkStudent(disconnectState.guardian, disconnectState.student);
    }
  };

  const handleOpenParentEditModal = (guardian: Guardian): void => {
    // open 'Edit Parent' modal
    setEditState({ guardian });
  };

  const handleUpdateParentSuccess = (guardian: Guardian): void => {
    // propagate guardian changes
    setGuardian(guardian);

    // refresh 'Parent Details' modal
    setSelectedState((selected) => ({ ...selected, guardian }));

    // close 'Edit Parent' modal
    setEditState(undefined);

    showSuccessToast({
      title: t("Parent details updated"),
      description: `${t("The details of")} ${guardian.firstName} ${guardian.lastName} ${t("have been successfully updated")}`,
    });
  };

  const handleUpdateParentError = (message: string): void => {
    showErrorToast({ title: "Something went wrong", description: message });
  };

  const handleCloseParentEditModal = (): void => {
    setEditState(undefined);
  };

  const handleOpenPurgeParentsModal = (): void => {
    setShowPurgeParentsModal(true);
  };

  const handleClosePurgeParentsModal = (): void => {
    setShowPurgeParentsModal(false);
  };

  const handleOpenExportParentsModal = (): void => {
    setExported(false);
    setShowExportParentsModal(true);
  };

  const handleCloseExportParentsModal = (): void => {
    setShowExportParentsModal(false);
  };

  const purgeParents = (purgeSet: string): void => {
    Api.delete(
      `/config/ajax/devices/${device.id}/guardians/purge?purgeSet=${purgeSet}`,
      {},
      () => {
        handleSearchClear();
        handleClosePurgeParentsModal();
        showSuccessToast({
          title: "Purge Successful",
          description: "All existing parent student relationship links have been deleted successfully.",
        });
      },
      () => {
        showErrorToast({
          title: "Please try again",
          description: "An error occurred while purging the data.",
        });
      }
    );
  };

  return (
    <>
      <CardBasedPage
        title={title}
        breadcrumbs={breadcrumbs}
        actions={
          <Flex gap="sp24" justifyContent="end">
            <Button variant="primary" onClick={handleOpenExportParentsModal} disabled={loading}>
              {t("Export Parent Data")}
            </Button>
            {isSupportAdmin && (
              <Button variant="danger" disabled={loading || guardians.length === 0} onClick={handleOpenPurgeParentsModal}>
                {t("Purge Parents")}
              </Button>
            )}
          </Flex>
        }
      >
        <Box minHeight="200px" p="sp24">
          <Flex justifyContent="space-between" mb="sp16">
            <SearchBox
              value={searchTerm}
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              isDisabled={loading}
              width="536px"
              placeholder={t("Search for a parent by name or email")}
              aria-label={t("Parent Search")}
            />
          </Flex>
          <Table>
            <Thead position="sticky" top="0" zIndex={zIndices.thead}>
              <Tr ref={rowRef}>
                <Th columnName="parent.name" headerText={t("Parent Name")} />
                <Th columnName="parent.email" headerText={t("Email")} />
                <Th columnName="parent.linked_students" headerText={t("Students Added")} />
                <Th columnName="parent.claimed_students" headerText={t("Students Connected")} />
                <>
                  {storedCommunityConfig?.policyDelegation?.allowPolicyDelegation && (
                    <Th columnName="parent.delegated_students" headerText={t("Parental Controls Accepted")} />
                  )}
                </>
                <Th headerText="" />
              </Tr>
            </Thead>
            <TbodyInfiniteScroll
              parentElemId="ComponentWrapper"
              fetchData={() => handleLoadMore()}
              hasMore={hasNextPage || loading}
              loaded={guardians.length > 0 || !loading}
              searched={!!searchTerm}
            >
              {guardians.map((p) => (
                <Tr key={p.id} fontSize="md" color="text.title">
                  <Td>
                    <Text wordBreak="break-word">
                      {p.firstName} {p.lastName}
                    </Text>
                  </Td>
                  <Td>
                    <Text>
                      <Link className="clickable" onClick={() => handleOpenParentDetailsModal(p)}>
                        {p.email}
                      </Link>
                    </Text>
                  </Td>
                  <Td>
                    <Text>{String(p.linkCount ?? 0)}</Text>
                  </Td>
                  <Td>
                    <Text>{String(p.claimCount ?? 0)}</Text>
                  </Td>
                  <>
                    {storedCommunityConfig?.policyDelegation?.allowPolicyDelegation && (
                      <Td>
                        <Text>{String(p.delegatedCount ?? 0)}</Text>
                      </Td>
                    )}
                  </>
                  <ButtonTd buttonIconName="fa-arrow-right" onClick={() => handleOpenParentDetailsModal(p)} aria-label="View Details" />
                </Tr>
              ))}
            </TbodyInfiniteScroll>
          </Table>
        </Box>
      </CardBasedPage>
      <ParentDetailsModal
        open={!!selectedState}
        guardian={selectedState?.guardian ?? GuardianPlaceholder}
        students={selectedState?.students}
        policyDelegationEnabled={!!storedCommunityConfig?.policyDelegation?.allowPolicyDelegation}
        onClose={handleCloseParentDetailsModal}
        onDisconnect={handleDisconnectStudent}
        onEdit={handleOpenParentEditModal}
      />
      <StudentDisconnectModal
        event={disconnectState ?? DisconnectEventPlaceholder}
        open={!!disconnectState}
        onClose={handleCloseStudentDisconnectModal}
        onConfirm={handleConfirmStudentDisconnect}
      />
      <EditParentModal
        show={!!editState}
        guardian={editState?.guardian ?? GuardianPlaceholder}
        handleSuccess={handleUpdateParentSuccess}
        handleHide={handleCloseParentEditModal}
        handleFail={handleUpdateParentError}
      />
      <PurgeParentsModal isOpen={showPurgeParentsModal} onClose={handleClosePurgeParentsModal} purgeParents={purgeParents} />
      <ExportParentsModal
        isOpen={showExportParentsModal}
        loading={loading}
        exported={exported}
        onClose={handleCloseExportParentsModal}
        executeDownload={executeDownload}
      />
    </>
  );
};

export default ParentManagement;
