import React, { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Button,
  Icon,
  Link,
  Modal,
  ModalBody,
  InlineNotification,
  SearchBox,
  Text,
  useTranslation,
  useToast,
} from "@familyzone/component-library";
import Api from "../../utils/Api";
import SessionStore from "../../stores/SessionStore";
import { Guardian, GuardianPlaceholder } from "../../types/Community";
import { UserCMS } from "../../types/User";
import { successToastLinkText, successToastTitle } from "../config/ManageUser/ManageUserHelpers";
import { UserUMS } from "../../types/Users";

interface SearchParentModalProps {
  show: boolean;
  handleHide: () => void;
  handleSuccess: (guardian: Guardian) => void;
  handleFail: (message: string) => void;
  handleAddParent: () => void;
  guardianIdsToExclude: string[];
  user: UserCMS | UserUMS;
}

interface Notification {
  status: "error" | "warning";
  title: string;
  message: string | ReactElement;
}

const SearchParentModal: React.FC<SearchParentModalProps> = ({
  show,
  handleHide,
  handleSuccess,
  handleFail,
  handleAddParent,
  guardianIdsToExclude,
  user,
}: SearchParentModalProps) => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  const [parents, setParents] = useState<Guardian[]>([]);
  const [selectedParent, setSelectedParent] = useState<Guardian>(GuardianPlaceholder);
  const [parentSelected, setParentSelected] = useState(false);
  const [notification, setNotification] = useState<Notification | null>(null);

  const [showAddNewParentButton, setShowAddNewParentButton] = useState(false);
  const [disableAddParentButton, setDisableAddParentButton] = useState(false);

  // Update 'searchQuery' after 0.5 second from the last update of 'debouncedSearchQuery'
  useEffect(() => {
    const timer = setTimeout(() => setSearchQuery(debouncedSearchQuery), 500);
    return () => clearTimeout(timer);
  }, [debouncedSearchQuery]);

  // Submit a new search
  useEffect(() => {
    if (searchQuery !== "") {
      Api.get(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
        `/config/ajax/devices/${SessionStore.getSelectedDevice()}/guardians/search?q=${searchQuery}`,
        function (result: { guardians: Guardian[] }) {
          let searchResults: Guardian[] = [];
          if (result.guardians.length > 0) {
            const filteredList = result.guardians.filter((guardian) => {
              return !guardianIdsToExclude.includes(guardian.id);
            });
            searchResults = filteredList.slice(0, 5);
          }

          setParents(searchResults);
          // To avoid duplicates, only show them the option to manually add a parent after their 1st search
          setShowAddNewParentButton(true);
          if (searchResults.length === 0) {
            setNotification({
              status: "warning",
              title: t("No search results were found"),
              message: t("Please try a different query or add a new parent."),
            });
          }
        },
        function () {
          setNotification({
            status: "error",
            title: t("Something went wrong"),
            message: t("Sorry, we were unable to get search results. Please try again."),
          });
        }
      );
    } else {
      setParents([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleSelectedParentChange = (parent: Guardian) => {
    if (parent.sourceType !== "LOCAL" && parent.sourceType !== "CSV") {
      setDisableAddParentButton(true);
      setNotification({
        status: "error",
        title: t("The parent cannot be added"),
        message: (
          <Box>
            <Text>
              {t(
                "This parent was imported from an external system and cannot be manually added to a student. Select another record, or request your admin for changes."
              )}
            </Text>
            <Link href="https://docs.linewize.net/help/modifying-parent-data-for-community" isExternal>
              Learn More
            </Link>
          </Box>
        ),
      });
    } else {
      setDisableAddParentButton(false);
    }
    setParentSelected(true);
    setSelectedParent(parent);
  };

  const handleLinkParent = () => {
    const guardianId = selectedParent.id;
    Api.put(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
      `/config/ajax/devices/${SessionStore.getSelectedDevice()}/users/${user.username}/guardians/${guardianId}/link`,
      {},
      () => {
        handleSuccess(selectedParent);
        handleCloseModal();
        showSuccessToast(successToastTitle, successToastLinkText(user, selectedParent));
      },
      () => {
        handleFail("Sorry, we were unable to link the selected parent. Please try again later.");
      }
    );
  };

  const handleCloseModal = () => {
    setSearchQuery("");
    setDebouncedSearchQuery("");
    setNotification(null);
    setParentSelected(false);
    setShowAddNewParentButton(false);
    setSelectedParent(GuardianPlaceholder);
    handleHide();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotification(null);
    setParentSelected(false);
    setSelectedParent(GuardianPlaceholder);
    setDebouncedSearchQuery(event.target.value);
  };

  const handleNewParent = () => {
    handleCloseModal();
    handleAddParent();
  };

  const { successToast } = useToast();
  const showSuccessToast = (title: string, message: string) => {
    successToast({
      title: t(title),
      description: t(message),
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Modal headerText={t("Add Parent")} isOpen={show} onClose={handleCloseModal} size="sm">
      <ModalBody>
        <Text fontSize="md" fontWeight="bold" color="text.paragraph.regular" role="heading">
          {t("Search Parent")}
        </Text>
        <SearchBox data-testid="search-parent-input" placeholder="" onChange={handleChange} onClear={() => setSearchQuery("")} />
        <Box>
          {!parentSelected && parents.length >= 1 && (
            <Box className="search-results">
              {parents.map((parent: Guardian) => (
                <Box
                  data-testid="search-parent-modal-search-result"
                  className="search-result"
                  key={parent.id}
                  onClick={() => handleSelectedParentChange(parent)}
                >
                  <Box>
                    {parent.firstName} {parent.lastName}
                  </Box>
                  <Box>{parent.email}</Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box>
          {notification && (
            <Box mt="sp16">
              <InlineNotification
                status={notification.status}
                notificationTitle={notification.title}
                notificationDescription={notification.message}
              />
            </Box>
          )}
        </Box>
        <Box>
          {showAddNewParentButton && !parentSelected && (
            <Box mt="sp24">
              <Button
                variant="secondary"
                mr="20px"
                leftIcon={<Icon icon="fa-plus" variant="solid" color="brand.400" />}
                onClick={handleNewParent}
              >
                {t("Add a new parent")}
              </Button>
            </Box>
          )}
        </Box>
        <Box>
          {parentSelected && (
            <Box data-testid="search-parent-selected-parent">
              <Text fontWeight="bold">{t("Parent Details")}</Text>
              <Box>{t(`Email: ${selectedParent.email}`)}</Box>
              <Box>{t(`First Name: ${selectedParent.firstName}`)}</Box>
              <Box>{t(`Last Name: ${selectedParent.lastName}`)}</Box>
              <Box>{t(`Source Type: ${selectedParent.sourceType}`)}</Box>
            </Box>
          )}
        </Box>
        <Box mt="sp24">
          <Button variant="primary" disabled={disableAddParentButton || !parentSelected} onClick={handleLinkParent}>
            {t("Add Parent")}
          </Button>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default SearchParentModal;
