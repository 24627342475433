import React from "react";
import { orderBy } from "lodash";

import FlexibleTable from "../modules/FlexibleTable";
import LayoutConfig from "../utils/LayoutConfig";

import SessionActions from "../actions/SessionActions";
import SessionStore from "../stores/SessionStore";
import PropTypes from "prop-types";
import Api from "../utils/Api";
import "../../css/Devices.css";
import GlobalDatePickerVisibilityActions from "../actions/GlobalDatePickerVisibilityActions";

import Link from "./Link";

import {
  Flex,
  Box,
  SearchBox,
  Button,
  withTranslation,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  ButtonTd,
  TbodyInfiniteScroll,
} from "@familyzone/component-library";
import { zIndices } from "../utils/ZIndexUtil";

/**
 * When Devices component loads, it calls two backend APIs. A local lightweight query /sites,
 * which talks to AMS and returns an object {deviceid, name, region} for each device.
 * In parallel this component trigger heavyweight /ajax/devices (CMS) via AltJs Store that
 * fetches detailed info for all devices. When the light query returns, Devices page is rendered
 * with list of devices, and when heavy query resolves, again updated with state and operations.
 * NOTE: In the future, consider refactoring so that we DON'T need to request for detailed
 * information for all devices when page loads, but rather request info on-demand. This
 * will reduce load on servers, reduce payload transmitted and increase response time.
 */
class Devices extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      devices: [],
      loaded: false,
      supportAdmin: false,
      fallbackDevices: [],
      fallbackLoaded: false,
      searchTerm: "",
      sortDirection: "desc",
      sortColumn: "device_stats.last_status",
      shownRowCount: 30,
    };
  }

  componentDidMount() {
    Api.get("/sites", this.handle_UpdateSiteInfo);
    SessionStore.listen(this.onChange);
    setTimeout(() => {
      SessionActions.deselectDevice();
      SessionActions.fetchAvailableDevices();
      GlobalDatePickerVisibilityActions.hideGlobalDatePicker();
    }, 0);
  }

  handle_UpdateSiteInfo = (data) => {
    let newFallbackDevices = [];
    for (let device of data["result"]) {
      let shouldAdd = true;
      for (let availableDevice of this.state.devices) {
        if (availableDevice["deviceid"] === device["deviceid"]) {
          shouldAdd = false;
          break;
        }
      }
      if (shouldAdd) {
        newFallbackDevices.push(device);
      }
    }
    this.setState({
      fallbackDevices: newFallbackDevices,
      fallbackLoaded: true,
    });
  };

  componentWillUnmount() {
    SessionStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      devices: SessionStore.getAvailableDevices(),
      loaded: SessionStore.getAvailableDevicesLoaded(),
      supportAdmin: SessionStore.isSupportAdmin(),
    });
  };

  select_Device = (device) => {
    let starting_page = [
      { role: "owner", page: "/surfwize" },
      { role: "surfwize_admin", page: "/surfwize" },
      { role: "surfwize_reporting", page: "/surfwize" },
      { role: "surfwize_filtering", page: "/filtering" },
      { role: "edgewize", page: "/edgewize" },
      { role: "surfwize_settings", page: "/config" },
      { role: "surfwize_guest_settings", page: "/config/device/userdb/guests" },
      { role: "classroom_admin", page: "/surfwize" },
      { role: "classroom_ed-tech", page: "/surfwize" },
      { role: "community_admin", page: "/config/device/community" },
      { role: "surfwize_cloud_filter_admin", page: "/surfwize" },
    ];

    /* Are we in the right region? In prod we show devices for us-1 and au-1 */
    if (device["device"]["region"] !== LayoutConfig.region()) {
      if (device["device"]["region"] === "us-1") {
        window.location = "https://schoolmanager.us-1.familyzone.io/devices/select/" + device["deviceid"];
        return;
      } else if (device["device"]["region"] === "syd-2") {
        window.location = "https://schoolmanager.au-1.familyzone.io/devices/select/" + device["deviceid"];
        return;
      }
    }

    /* To avoid the user getting confused, we need to figure out the best landing page */
    SessionActions.changeDevice(device);
    if (this.state.supportAdmin) {
      this.context.router.push("/surfwize");
      return;
    }

    for (let permission of starting_page) {
      for (let actual_permission of device["permissions"]) {
        if (permission["role"] === actual_permission) {
          this.context.router.push(permission["page"]);
          return;
        }
      }
    }
  };

  handle_ClickManageDevice = (device) => {
    SessionActions.changeDevice(device);
    this.context.router.push("/managedevice");
  };

  handler__clickAdd = () => {
    this.setState({
      visible__add_group: true,
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_group: false,
    });
  };

  render__buttons = () => {
    return <div />;
  };

  isFallback = () => {
    //return false;
    return this.state.fallbackLoaded && !this.state.loaded;
  };

  getColumns = () => {
    let self = this;
    if (this.isFallback()) {
      //device["device"]["region"]
      //device["deviceid"]
      return [
        {
          title: "Name",
          data: function (row) {
            return (
              <Link
                className="clickable"
                onClick={() =>
                  self.select_Device({
                    device: {
                      region: row["region"],
                      user_defined_name: row["name"],
                    },
                    deviceid: row["deviceid"],
                  })
                }
              >
                {row["name"]}
              </Link>
            );
          },
          onclick: undefined,
          search: FlexibleTable.search__single_string_field("name"),
          sortable: FlexibleTable.sortable__single_string_field("name"),
        },
        {
          title: "Device ID",
          data: function (row) {
            return (
              <Link
                className="clickable"
                onClick={() =>
                  self.select_Device({
                    device: {
                      region: row["region"],
                      user_defined_name: row["name"],
                    },
                    deviceid: row["deviceid"],
                  })
                }
              >
                {row["deviceid"]}
              </Link>
            );
          },
          onclick: undefined,
          search: FlexibleTable.search__single_string_field("deviceid"),
          sortable: FlexibleTable.sortable__single_string_field("deviceid"),
        },
        {
          title: "Operations",
          data: function (row) {
            return "--";
          },
        },
      ];
    }
    return [
      {
        title: "Name",
        data: function (row) {
          return (
            <Link className="clickable" onClick={() => self.select_Device(row)}>
              {row["device"]["user_defined_name"]}
            </Link>
          );
        },
        onclick: undefined,
        search: function (row, search_expression) {
          if (search_expression === "") {
            return true;
          } else {
            if (row["device"]["user_defined_name"]) {
              return row["device"]["user_defined_name"].toLowerCase().includes(search_expression.toLowerCase());
            }

            return false;
          }
        },
        sortable: function (row_a, row_b) {
          let nameA = row_a["device"]["user_defined_name"] || "";
          let nameB = row_b["device"]["user_defined_name"] || "";

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        },
      },
      {
        title: "Device ID",
        data: function (row) {
          return (
            <Link className="clickable" onClick={() => self.select_Device(row)}>
              {row["deviceid"]}
            </Link>
          );
        },
        onclick: undefined,
        search: function (row, search_expression) {
          if (search_expression === "") {
            return true;
          } else {
            return row["deviceid"].toLowerCase().includes(search_expression.toLowerCase());
          }
        },
        sortable: FlexibleTable.sortable__single_string_field("deviceid"),
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <Link className="clickable" onClick={() => self.handle_ClickManageDevice(row)}>
              Settings
            </Link>
          );
        },
        onclick: undefined,
      },
    ];
  };

  getData = () => {
    if (this.isFallback()) {
      return this.state.fallbackDevices;
    }
    return this.state.devices;
  };

  isLoaded = () => {
    return this.state.loaded || (this.state.fallbackLoaded && SessionStore.isSupportAdmin());
  };

  render__content = () => {
    return <FlexibleTable columns={this.getColumns()} data={this.getData()} sort_asc={false} loaded={this.isLoaded()} />;
  };

  handleSearchChange = (evt) => {
    this.setState({
      searchTerm: evt.target.value,
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
    });
  };

  handleSortChange = (sortColumn, sortDirection) => {
    this.setState({
      sortColumn,
      sortDirection,
    });
  };

  handleNewDevice = () => {
    this.context.router.push("/device/create");
  };

  showMore = () => {
    this.setState({
      shownRowCount: this.state.shownRowCount + 20,
    });
  };

  render() {
    const { t } = this.props;
    const { searchTerm, sortColumn, sortDirection } = this.state;

    return (
      <Flex bg="neutrals.0" m="sp24" p="sp24" borderRadius="sp12" flexDir="column" mx="auto" maxWidth="1200px">
        <Flex justifyContent="space-between" mb="sp16">
          <SearchBox
            value={this.state.searchTerm}
            onChange={this.handleSearchChange}
            onClear={this.handleSearchClear}
            isDisabled={!this.state.devices}
            w="530px"
          />
          <Button variant="primary" onClick={this.handleNewDevice}>
            {t("Add New Device")}
          </Button>
        </Flex>
        {/*We got `DevicesWrapper` here and pass it to InfiniteScroll because InfiniteScroll couldn't recognise the correct parent and trigger loadMore() function*/}
        <Box overflowY="auto" minWidth="900px" id="DevicesWrapper" data-testid="DevicesWrapper">
          <Table width="100%">
            <Thead position="sticky" top="0" zIndex={zIndices.thead}>
              <Tr>
                <Th
                  columnName="device.user_defined_name"
                  handleSort={this.handleSortChange}
                  sortDirection={sortColumn === "device.user_defined_name" && sortDirection}
                  headerText={t("Device Name")}
                />
                <Th
                  columnName="deviceid"
                  handleSort={this.handleSortChange}
                  sortDirection={sortColumn === "deviceid" && sortDirection}
                  headerText={t("Device ID")}
                />
              </Tr>
            </Thead>
            <TbodyInfiniteScroll
              fetchData={this.showMore}
              hasMore={this.getData().length >= this.state.shownRowCount}
              parentElemId="DevicesWrapper"
              loaded={this.isLoaded()}
              searched={!!this.state.searchTerm}
            >
              {this.getData() &&
                orderBy(this.getData(), sortColumn, sortDirection)
                  .filter(
                    (device) =>
                      !searchTerm ||
                      device.deviceid.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      device.device?.user_defined_name?.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .filter((_r, i) => i <= this.state.shownRowCount)
                  .map((device) => (
                    <Tr key={device.deviceid}>
                      <Td verticalAlign="middle">
                        <Link onClick={() => this.select_Device(device)}>{device.device?.user_defined_name}</Link>
                      </Td>
                      <Td>
                        <Link onClick={() => this.select_Device(device)}>{device.deviceid}</Link>
                      </Td>
                      <ButtonTd buttonIconName="fa-gear" buttonText={t("Settings")} onClick={() => this.handle_ClickManageDevice(device)} />
                    </Tr>
                  ))}
            </TbodyInfiniteScroll>
          </Table>
        </Box>
      </Flex>
    );
  }
}

Devices.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default withTranslation()(Devices);
