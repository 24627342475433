import { Center, Divider } from "@chakra-ui/layout";
import { Box, Button, Flex, Icon, Modal, Spinner, Text, useTranslation } from "@familyzone/component-library";
import { Tag, TagLabel } from "@chakra-ui/tag";
import React from "react";
import { DisconnectEvent, DisconnectType, Guardian, Student, isLocalGuardian } from "../../types/Community";

interface Props {
  open: boolean;
  guardian: Guardian;
  students?: Student[];
  policyDelegationEnabled: boolean;
  onClose: () => void;
  onDisconnect: (event: DisconnectEvent) => void;
  onEdit: (guardian: Guardian) => void;
}

const ClaimedStudent: React.FC<{
  student: Student;
  policyDelegationEnabled: boolean;
  onUnclaim: () => void;
}> = ({ student, policyDelegationEnabled, onUnclaim }) => {
  const { t } = useTranslation();

  return (
    <Flex py="sp8" px="sp12" backgroundColor="neutrals.0" borderRadius="sm" alignItems="center">
      <Box flexGrow={1}>
        <Text fontFamily="Roboto" fontSize="md" fontWeight="medium" color="text.title">
          {student.firstName} {student.middleName ?? ""} {student.lastName}
          {policyDelegationEnabled && student.delegated && (
            <Tag variant="outline" backgroundColor="brand.200" color="brand.500" size="compact" ml={6} display={"inline"}>
              <TagLabel>{t("Parental Controls Accepted")}</TagLabel>
            </Tag>
          )}
        </Text>

        <Text fontFamily="Roboto" fontSize="md" fontWeight="normal" color="text.paragraph.regular">
          {student.email ?? student.username}
        </Text>
      </Box>
      <Box alignItems="center">
        <Button size="compact" variant="primary" onClick={onUnclaim} leftIcon={<Icon icon="fa-link-simple-slash" />}>
          {t("Disconnect")}
        </Button>
      </Box>
    </Flex>
  );
};

const UnclaimedStudent: React.FC<{
  student: Student;
  disabled?: boolean;
  policyDelegationEnabled: boolean;
  onUnlink: () => void;
}> = ({ student, disabled, policyDelegationEnabled, onUnlink }) => {
  const { t } = useTranslation();

  return (
    <Flex py="sp8" px="sp12" backgroundColor="neutrals.0" borderRadius="sm" alignItems="center">
      <Box flexGrow={1}>
        <Text fontFamily="Roboto" fontSize="md" fontWeight="medium" color="text.title">
          {student.firstName} {student.middleName ?? ""} {student.lastName}
          {policyDelegationEnabled && student.delegated && (
            <Tag variant="outline" backgroundColor="brand.200" color="brand.500" size="compact" ml={6}>
              <TagLabel>{t("Parental Controls Accepted")}</TagLabel>
            </Tag>
          )}
        </Text>

        <Text fontFamily="Roboto" fontSize="md" fontWeight="normal" color="text.paragraph.regular">
          {student.email ?? student.username}
        </Text>
      </Box>
      <Flex direction="row" alignItems="center">
        <Button size="compact" variant="primary" onClick={onUnlink} leftIcon={<Icon icon="fa-circle-minus" />} disabled={disabled}>
          {t("Remove")}
        </Button>
      </Flex>
    </Flex>
  );
};

const ParentDetailsModal: React.FC<Props> = ({
  guardian,
  students,
  open,
  policyDelegationEnabled,
  onClose,
  onDisconnect,
  onEdit,
}: Props) => {
  const { t } = useTranslation();

  const handleUnclaim = (guardian: Guardian, student: Student): void => {
    onDisconnect({ guardian, student, type: DisconnectType.Unclaim });
  };

  const handleUnlink = (guardian: Guardian, student: Student): void => {
    onDisconnect({ guardian, student, type: DisconnectType.Unlink });
  };

  const claimedStudents = students ? students.filter((s) => s.claimed) : [];
  const unclaimedStudents = students ? students.filter((s) => !s.claimed) : [];
  const localGuardian = isLocalGuardian(guardian);
  const canEdit = localGuardian && claimedStudents.length === 0;
  const canUnlink = localGuardian;

  return (
    <Modal isOpen={open} onClose={onClose} size="md">
      <Flex mb="sp24">
        <Flex flexDirection="column" flexGrow={1}>
          <Text as="h6" fontFamily="Poppins" fontSize="xs" fontWeight="medium" color="text.paragraph.regular" textTransform="uppercase">
            {t("Parent")}
          </Text>
          <Text as="h2" fontFamily="Poppins" fontSize="lg" fontWeight="medium" color="text.title">
            {guardian.firstName} {guardian.lastName}
          </Text>
          <Text fontFamily="Roboto" fontSize="sm" fontWeight="normal" color="text.paragraph.regular">
            {guardian.email}
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Button
            size="compact"
            variant="outline"
            onClick={() => onEdit(guardian)}
            leftIcon={<Icon icon="fa-pencil" />}
            disabled={!canEdit}
          >
            {t("Edit Parent Details")}
          </Button>
        </Flex>
      </Flex>

      <Flex mb="sp16" color="text.title">
        <Center width="20px" height="20px" borderWidth="2px" borderRadius="100px" borderColor="brand.400">
          <Text fontSize="sm" fontWeight="semibold" lineHeight="18px">
            {students?.length ?? 0}
          </Text>
        </Center>
        <Text ml="sp8">Total Students Added</Text>
        <Center ml="sp24" width="20px" height="20px" borderWidth="0px" borderRadius="100px" backgroundColor="accent.turquoise.100">
          <Text fontSize="sm" fontWeight="semibold" lineHeight="20px">
            {claimedStudents.length}
          </Text>
        </Center>
        <Text ml="sp8">Connected Students</Text>
        <Center ml="sp24" width="20px" height="20px" borderWidth="0px" borderRadius="100px" backgroundColor="neutrals.20">
          <Text fontSize="sm" fontWeight="semibold" lineHeight="20px">
            {unclaimedStudents.length}
          </Text>
        </Center>
        <Text ml="sp8">Disconnected Students</Text>
      </Flex>

      <Divider borderColor="neutrals.40" />

      <Flex mt="sp12" flexDirection="column" minHeight="100px" gap="sp12" overflowY="auto">
        {students === undefined && (
          <Flex flexGrow={1} alignItems="center" justifyContent="center" data-testid="spinner">
            <Spinner />
          </Flex>
        )}

        {claimedStudents.length > 0 && (
          <Box p="sp16" backgroundColor="accent.turquoise.100" borderRadius="sm" data-testid="claimed-students-section">
            <Text fontFamily="Roboto" fontSize="md" fontWeight="medium" color="text.title">
              {t("Connected Students")}
            </Text>
            <Text fontFamily="Roboto" fontSize="sm" fontWeight="normal" color="text.paragraph.light">
              {t("Students listed below have been connected to this parent through Qustodio app.")}
            </Text>

            <Flex gap="sp4" mt="sp12" flexDirection="column">
              {claimedStudents.map((s) => (
                <ClaimedStudent
                  key={s.id}
                  student={s}
                  policyDelegationEnabled={policyDelegationEnabled}
                  onUnclaim={() => handleUnclaim(guardian, s)}
                />
              ))}
            </Flex>
          </Box>
        )}

        {unclaimedStudents.length > 0 && (
          <Box p="sp16" backgroundColor="neutrals.20" borderRadius="sm" data-testid="unclaimed-students-section">
            <Text fontFamily="Roboto" fontSize="md" fontWeight="bold" color="text.title">
              {t("Disconnected Students")}
            </Text>
            <Text fontFamily="Roboto" fontSize="sm" fontWeight="normal" color="text.paragraph.light">
              {t("Students listed below have been added to this parent, but are yet to be connected.")}
            </Text>

            <Flex gap="sp4" mt="sp12" flexDirection="column">
              {unclaimedStudents.map((s) => (
                <UnclaimedStudent
                  key={s.id}
                  student={s}
                  policyDelegationEnabled={policyDelegationEnabled}
                  disabled={!canUnlink}
                  onUnlink={() => handleUnlink(guardian, s)}
                />
              ))}
            </Flex>
          </Box>
        )}
      </Flex>
    </Modal>
  );
};

export default ParentDetailsModal;
