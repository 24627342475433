import { IFlatChange, diff, flattenChangeset } from "json-diff-ts";
import * as _ from "lodash";
import { Permission } from "../../../types/Authorization";

// SAFETY_NET becomes Safety Net
// COMMUNITY becomes Community
export const titleize = (str: string): string => {
  return str
    .split(/\s+|_/)
    .map((w) => w.charAt(0).toUpperCase() + w.substring(1).toLowerCase())
    .join(" ");
};

const NEW_KEY_COLOR = "#97cfff"; // blue
const UPDATED_KEY_COLOR = "yellow";
const REMOVED_KEY_COLOR = "#f9c0c0"; // red

export const getAuditDiffHtml = (oldObject: object | undefined, newObject: object | undefined): [string, string] => {
  if (oldObject && newObject) {
    const diffs = diff(oldObject, newObject);
    const flatChanges: IFlatChange[] = flattenChangeset(diffs);

    const highlightedPaths: string[] = [];
    for (const change of flatChanges) {
      let highlightObject: object = {};
      const changeType = getType(change);
      const color = changeType === "UPDATE" ? UPDATED_KEY_COLOR : changeType === "ADD" ? NEW_KEY_COLOR : REMOVED_KEY_COLOR;

      // determine which object, "after" or "before", to highlight
      highlightObject = getHighlightObject(change) === "after" ? newObject : oldObject;

      // get the changed key path for array
      const changedArrayPath = getChangedArray(change);
      const isChangedArray = !!changedArrayPath;
      let path = changedArrayPath;
      if (!path) {
        // get the changed key path for object
        path = change.path === "$" ? change.key : change.path.replace("$.", "");
      }

      // ensure item in the array which is already highlighted is not processed again
      if (highlightedPaths.indexOf(path) > -1) continue;

      highlightedPaths.push(path);

      // eslint-disable-next-line
      const value = _.get(highlightObject, path); // maintain original changed value
      // if the change is items inside an array, highlight the key
      // if the key is newly added/removed, highlight the key
      if (isChangedArray || ["ADD", "REMOVE"].includes(changeType)) {
        highlightObject = _.omit(highlightObject, path); // remove the changed key to replace with new highlighted key later
        const paths = path.split("."); // paths = ["policyDelegation", "excludeGroups"] to remove the last key next line.
        const key = paths.pop(); // key = "excludeGroups", paths = ["policyDelegation"]

        // eslint-disable-next-line
        _.set(highlightObject, `${paths.join(".")}[<mark style='background: ${color}'>${key}</mark>]`, value); // now replace the key with highlighted key
      } else {
        // otherwise, highlight the value
        // eslint-disable-next-line
        _.set(highlightObject, path, `<mark style='background: ${color}'>${value}</mark>`);
      }

      // update the original object with the highlighted key or value
      if (getHighlightObject(change) === "after") {
        newObject = highlightObject;
      } else {
        oldObject = highlightObject;
      }
    }
  }

  const beforeHtml = oldObject ? getAuditDiffString(oldObject) : "";
  const afterHtml = newObject ? getAuditDiffString(newObject) : "";

  return [beforeHtml, afterHtml];
};

const getType = (change: IFlatChange) => {
  const isObjectArray = !!getChangedArray(change);
  if (isObjectArray) {
    return "UPDATE";
  }

  return change.type;
};

const getChangedArray = (change: IFlatChange): string | undefined => {
  // "$.policyDelegation.excludeGroups" -> "policyDelegation.excludeGroups"
  // "$.policyDelegation.excludeGroups[0]" -> "policyDelegation.excludeGroups"
  // "$.policyDelegation.excludeGroups[0].username" -> "policyDelegation.excludeGroups"
  // "$" -> null
  // "$.username" -> null
  const matches = change.path.match(/\$\.(.*)\[.*/);
  return matches?.[1];
};

const getHighlightObject = (change: IFlatChange) => {
  const isObjectArray = !!getChangedArray(change);
  if (isObjectArray) {
    return "after";
  }

  return ["UPDATE", "ADD"].includes(change.type) ? "after" : "before";
};

const getAuditDiffString = (object: object) => {
  return JSON.stringify(object, null, 2);
};

export const isCommunityAuditsAccessible = (permissions: Permission[], isSupportAdmin: boolean): boolean => {
  return permissions.some((p) => p === Permission.CommunityAdmin || p === Permission.Owner) || isSupportAdmin || false;
};
