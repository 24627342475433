export interface FeatureFlags {
  "connection-search-report"?: boolean;
  "agent-p2p-screenshots"?: boolean;
  "classwize-teacher-student-chat"?: boolean;
  "display-mobile-apps"?: boolean;
  config_agents_filtering_bypass?: boolean;
  connect_agents_verdict_v3?: boolean;
  filtering_agents_custom_http_headers?: boolean;
  device_specs?: boolean;
  "shr-enabled"?: boolean;
  "show-student-wellbeing-menu-item"?: boolean;
  "show-student-behaviour-menu-item"?: boolean;
  "show-student-activity-menu-item"?: boolean;
  "show-distracted-student-menu-item"?: boolean;
  "show-activity-compliance-menu-item"?: boolean;
  "show-applications-usage-menu-item"?: boolean;
  configure_restrictive_mode?: boolean;
  "legacy-criteria-selector"?: boolean;
  "show-cloudsafe-menu-item"?: boolean;
  "companion-mode-enabled"?: boolean;
  "companion-mode-lite-enabled"?: boolean;
  "companion-mode-auto-policies"?: boolean;
  qoria_dns?: boolean;
  "show-manage-user-password"?: boolean;
  "hide-red-flags"?: boolean;
  "hide-dashboard-teacher-signin"?: boolean;
  "show-https-inspection-menu-item"?: boolean;
  "show-clever-sync-menu-item"?: boolean;
}

export const FeatureFlagsEquals = (f1?: FeatureFlags, f2?: FeatureFlags): boolean => {
  if (!f1 || !f2) {
    return f1 === f2;
  }

  const k1 = Object.keys(f1);
  const k2 = Object.keys(f2);

  if (k1.length === k2.length) {
    for (const k of k1) {
      const tk = k as keyof FeatureFlags;
      if (f1[tk] !== f2[tk]) {
        return false;
      }
    }
    return true;
  }

  return false;
};

export const HasExpectedFlags = (expected: FeatureFlags, flags: FeatureFlags): boolean => {
  for (const key in expected) {
    const expectedKey = key as keyof FeatureFlags;
    const expectedValue = expected[expectedKey];
    const actualValue = flags[expectedKey];

    if (typeof expectedValue === "boolean" || typeof actualValue === "boolean") {
      if (!!expectedValue !== !!actualValue) {
        return false;
      }
    } else if (expectedValue !== actualValue) {
      return false;
    }
  }
  return true;
};
