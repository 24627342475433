import React, { useEffect, useState } from "react";
import { Box, Flex, Modal, Tag, Text, useTranslation } from "@familyzone/component-library";
import { GroupSearchSelector } from "../GroupSearch/GroupSearchSelector";
import { UserSearchSelector } from "../UserSearch/UserSearchSelector";
import { GroupOption } from "../../types/Groups";
import { UserOption } from "../../types/Users";
import { Group, User } from "../../types/Community";

interface ManageExclusionsModalProps {
  headerText?: string;
  isOpen: boolean;
  isInclusionModal?: boolean;
  onSave: (newExcludeGroups: Group[], newExcludeUsers: User[]) => void;
  onClose: () => void;
  groups: Group[];
  users: User[];
  testId?: string;
}
const ManageExclusionsModal: React.FC<ManageExclusionsModalProps> = ({
  headerText,
  isOpen,
  isInclusionModal,
  onSave,
  onClose,
  groups,
  users,
  testId,
}: ManageExclusionsModalProps) => {
  const { t } = useTranslation();

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [excludeGroups, setExcludeGroups] = useState<Group[]>(groups);
  const [excludeUsers, setExcludeUsers] = useState<User[]>(users);

  const onCloseExclusions = () => {
    setExcludeGroups(groups);
    setExcludeUsers(users);
    setHasChanges(false);
    onClose();
  };

  const onSaveExclusions = () => {
    setHasChanges(false);
    onSave(excludeGroups, excludeUsers);
  };

  const onChangeGroups = (group: GroupOption | null) => {
    if (group && typeof group.value === "string" && !excludeGroups.some((groupItem) => groupItem.id === group.id)) {
      const newGroup = {
        id: group.stableId || "",
        description: group.description || group.name || group.label || "",
        distinguishedName: group.distinguishedName || group.name || "",
        sourceName: group.name || "",
        sourceType: group.sourceType,
      };
      setExcludeGroups((excludeGroups) => [...excludeGroups, newGroup]);
      setHasChanges(true);
    }
  };

  const onChangeUsers = (user: UserOption | null) => {
    if (user && typeof user.value === "string" && !excludeUsers.some((userItem) => userItem.username === user.username)) {
      const newUser: User = {
        id: user.stableId || "",
        username: user.username || "",
      };
      setExcludeUsers((excludeUsers) => [...excludeUsers, newUser]);
      setHasChanges(true);
    }
  };

  const onRemoveGroup = (groupToRemove: Group) => {
    setExcludeGroups((excludeGroups) => excludeGroups.filter((group) => group.id !== groupToRemove.id));
    setHasChanges(true);
  };

  const onRemoveUser = (userToRemove: User) => {
    setExcludeUsers((excludeUsers) => excludeUsers.filter((user) => user.username !== userToRemove.username));
    setHasChanges(true);
  };

  useEffect(() => {
    if (isOpen) {
      // On open, re-set excludeGroups/Users as they
      // may have changed since this component
      // was created
      setExcludeGroups(groups);
      setExcludeUsers(users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      onClose={onCloseExclusions}
      headerText={headerText ?? (isInclusionModal ? t("Manage Inclusions") : t("Manage Exclusions"))}
      primaryCTALabel={isInclusionModal ? t("Save Inclusions") : t("Save Exclusions")}
      onPrimaryCTAClick={onSaveExclusions}
      primaryCTADisabled={!hasChanges}
      secondaryCTALabel={t("Cancel")}
      onSecondaryCTAClick={onCloseExclusions}
      contentProps={{ style: { overflow: "visible" } }}
    >
      <Box data-testid={testId}>
        <Flex flexDirection="row" mt="sp24" mb="sp16" border="0.5px solid" borderColor="neutrals.40" borderRadius="sm" p="sp16">
          <Flex flexDirection="column" width="sm">
            <Text>{isInclusionModal ? t("Include Groups") : t("Exclude Groups")}</Text>
          </Flex>
          <Flex flexDirection="column" ml="sp32">
            <Box width="md">
              <GroupSearchSelector placeholderText="Search for Group" clearOnSelect={true} handleChange={onChangeGroups} />

              <Flex flexDirection="row" flexWrap="wrap">
                {excludeGroups.map((r) => (
                  <Box mr="sp8" mt="sp8" key={r.id}>
                    <Tag
                      variant="dark"
                      isRemovable
                      tagLabel={r.description || r.distinguishedName || r.sourceName}
                      onRemoveClick={() => onRemoveGroup(r)}
                    />
                  </Box>
                ))}
              </Flex>
            </Box>
          </Flex>
        </Flex>

        <Flex flexDirection="row" mb="sp16" border="0.5px solid" borderColor="neutrals.40" borderRadius="sm" p="sp16">
          <Flex flexDirection="column" width="sm">
            <Text>{isInclusionModal ? t("Include Users") : t("Exclude Users")}</Text>
          </Flex>
          <Flex flexDirection="column" ml="sp32">
            <Box width="md">
              <UserSearchSelector placeholderText="Search for User" clearOnSelect={true} handleChange={onChangeUsers} />

              <Flex flexDirection="row" flexWrap="wrap">
                {excludeUsers.map((r) => (
                  <Box mr="sp8" mt="sp8" key={r.username}>
                    <Tag variant="dark" isRemovable tagLabel={r.username} onRemoveClick={() => onRemoveUser(r)} />
                  </Box>
                ))}
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ManageExclusionsModal;
