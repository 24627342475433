import { Box, Text, useTranslation } from "@familyzone/component-library";
import React from "react";

export interface StatusChipProps {
  action: number;
}

export const ACTION_BLOCKED = 0;
export const ACTION_ALLOWED = 1;

export const ALLOWED_TEXT = "Allowed";
export const BLOCKED_TEXT = "Blocked";

const StatusChip: React.FC<StatusChipProps> = ({ action }) => {
  const { t } = useTranslation();

  const text = action === ACTION_ALLOWED ? t(ALLOWED_TEXT) : t(BLOCKED_TEXT);
  const color = action === ACTION_ALLOWED ? "accent.green.100" : "accent.red.300";
  const textColor = action === ACTION_ALLOWED ? "neutrals.900" : "white";
  const mr = action === ACTION_ALLOWED ? undefined : "sp4";

  return (
    <Box bgColor={color} p="2px 8px" borderRadius="200px" mr={mr}>
      <Text color={textColor} fontSize="x-small" fontWeight="medium" letterSpacing="0.01em" lineHeight="16px">
        {text}
      </Text>
    </Box>
  );
};

export default StatusChip;
