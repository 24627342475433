import React from "react";
import PropTypes from "prop-types";
import RootNavItem from "./components/RootNavItem";
import NewFeedbackModal from "../modals/FeedbackModal";
import { Box, Fab, Flex, Icon, Overlay, Tooltip, withTranslation } from "@familyzone/component-library";
import AuthorizationWrapper from "../../modules/AuthorizationWrapper";
import uuid from "uuid/v4";
import { getNavRoutes } from "./navRoutes";
import { getApplianceNavRoutes } from "./applianceRoutes";

export const RootSideButtons = ({ onClickFeedback = () => "", onClickFeatureLab = () => "", active = null }) => {
  return (
    <Overlay flexDirection="row" left={0} bottom={0} justifyContent="row">
      <Tooltip label="Send Feedback" placement="top" variant="dark">
        <Box ml="sp12" mb="sp12">
          <Fab active={active === "feedback"} onClick={onClickFeedback} size="40px">
            <Icon boxSize="12px" icon="fa-paper-plane" variant="solid" color="neutrals.0" />
          </Fab>
        </Box>
      </Tooltip>

      {/* Commented out for now until we have another feature lab featue */}
      {/* <Tooltip label="Feature Lab" placement="top" variant="dark">
        <Box ml="sp12" mb="sp12">
          <Fab active={active === "featurelab"} onClick={onClickFeatureLab} size="40px">
            <Icon boxSize="12px" icon="fa-flask" data-testid="fa-flask" variant="solid" color="neutrals.0" />
          </Fab>
        </Box>
      </Tooltip> */}
    </Overlay>
  );
};

class Nav extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showFeedbackModal: false,
      navItems: [],
    };
    this.unsubAccountStore = null;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.path === "/devices" || props.path === "/device/create" || props.path === "/account") {
      return { navItems: getApplianceNavRoutes(props.t) };
    }
    return { navItems: getNavRoutes(props.t, props.menuFeatureToggles) };
  }

  is_selected = (path) => {
    return !!this.props.path.startsWith(path);
  };

  toggleFeedbackModal = () => {
    this.setState({
      showFeedbackModal: !this.state.showFeedbackModal,
    });
  };

  handleNavFeatureLab = () => {
    this.context.router.push("/featurelab");
  };

  renderNavItems() {
    return this.state.navItems
      .filter((rootItem) => !rootItem.hidden)
      .map((rootItem) => (
        <AuthorizationWrapper allowedRoles={rootItem.allowedRoles} key={uuid()}>
          <RootNavItem
            name={rootItem.name}
            to={rootItem.to}
            icon={rootItem.icon}
            childItems={rootItem.childItems}
            dataCy={rootItem.dataCy}
            isActive={this.is_selected(rootItem.to)}
            forceShowChildren={rootItem.forceShowChildren || this.props.path.startsWith(rootItem.to)}
            currentPath={this.props.path}
            isNew={rootItem.isNew}
          />
        </AuthorizationWrapper>
      ));
  }

  render() {
    return (
      <React.Fragment>
        {this.props.path !== "/devices" && (
          <Flex direction="column" bg="neutrals.900" height="auto" width="220px" flexShrink="0" position="sticky" top="0">
            <Flex
              direction="column"
              overflowY="scroll"
              overflowX="hidden"
              height="calc(100% - 40px)"
              css={{
                msOverflowStyle: "none" /* Internet Explorer 10+ */,
                scrollbarWidth: "none" /* Firefox */,
                "&::-webkit-scrollbar": {
                  display: "none" /* Safari and Chrome */,
                },
              }}
            >
              {this.renderNavItems()}
            </Flex>
            <NewFeedbackModal open={this.state.showFeedbackModal} onClose={this.toggleFeedbackModal} />
            <RootSideButtons onClickFeedback={this.toggleFeedbackModal} onClickFeatureLab={this.handleNavFeatureLab} />
          </Flex>
        )}
      </React.Fragment>
    );
  }
}

Nav.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default withTranslation()(Nav);
