import { create } from "zustand";
import { ContentAwareLicenseStatus, getContentAwareLicense } from "../utils/api/ContentAware";

/**
 * This store is used in `AppRoot` to decide access to certain routes
 * and conditionally render menu items and content on some pages.
 */

export const filterLicenseDefaults: FilterLicensesState = {
  contentAwareEnabled: false,
};

interface FilterLicensesState {
  contentAwareEnabled: boolean;
}

interface FilterLicensesActions {
  fetch: () => Promise<FilterLicensesState>;
  getAllLicenses: () => FilterLicensesState;
  getContentAwareEnabled: () => boolean;
  setContentAwareEnabled: (enabled: boolean) => void;
  reset: () => void;
}

export const useFilterLicensesStore = create<FilterLicensesState & FilterLicensesActions>((set, get) => ({
  contentAwareEnabled: filterLicenseDefaults.contentAwareEnabled,

  getAllLicenses: (): FilterLicensesState => {
    return get();
  },

  getContentAwareEnabled: (): boolean => {
    return get().contentAwareEnabled;
  },

  setContentAwareEnabled: (enabled: boolean): void => {
    set({ contentAwareEnabled: enabled });
  },

  fetch: async (): Promise<FilterLicensesState> => {
    let contentAwareEnabled = false;
    try {
      const contentAwareLicense = await getContentAwareLicense();
      contentAwareEnabled = contentAwareLicense.status === ContentAwareLicenseStatus.Active;
    } catch (error) {}

    set({ contentAwareEnabled });
    return get();
  },

  reset: (): void => {
    set(filterLicenseDefaults);
  },
}));
