import { Button } from "@chakra-ui/button";
import { Tag, TagLabel } from "@chakra-ui/tag";
import {
  Box,
  Flex,
  Option,
  SingleSearchSelector,
  Text,
  useTranslation,
  Icon,
  Tr,
  Td,
  useToast,
  TableIconButton,
} from "@familyzone/component-library";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import useSchoolCalendarStore, { RuleUpdateType, SchoolCalendarStore } from "../../../storez/SchoolCalendarStore";
import { NonSchoolRule, Frequency, RuleType, CalendarRule, RecurrenceID } from "../../../types/Calendar";
import { TableColumn } from "../../../types/table";
import CardBasedPage from "../../templates/CardBasedPage";
import SortSearchTable from "../../templates/SortSearchTable";
import DeleteNonSchoolDaysModal from "./DeleteNonSchoolDaysModal";
import AddNonSchoolDaysModal from "./AddNonSchoolDaysModal";
import { ConvertRuleForDisplay, RuleMatchesFilter } from "./CalendarUtils";

export const NonSchoolRulesPlaceholder: Readonly<NonSchoolRule> = Object.freeze({
  name: "",
  startDate: 12412541,
  startTime: 0,
  type: RuleType.OTHER,
  endDate: 12412541,
  endTime: 2359,
  recurrence: {
    frequency: Frequency.YEARLY,
    interval: 1,
  },
});

/* An Option where the value is always a number. */
interface NumericOption extends Option {
  value: number;
}

const NonSchoolRules: React.FC<{ nonSchoolDaysEnabled: boolean }> = ({ nonSchoolDaysEnabled }) => {
  const years: NumericOption[] = [];
  // Show the previous two years, current year, and 10 years in the future as options
  for (let i = -2; i < 11; i++) {
    years.push({ label: (new Date().getFullYear() + i).toString(), value: new Date().getFullYear() + i });
  }
  const months: NumericOption[] = [
    { label: "January", value: 0 },
    { label: "February", value: 1 },
    { label: "March", value: 2 },
    { label: "April", value: 3 },
    { label: "May", value: 4 },
    { label: "June", value: 5 },
    { label: "July", value: 6 },
    { label: "August", value: 7 },
    { label: "September", value: 8 },
    { label: "October", value: 9 },
    { label: "November", value: 10 },
    { label: "December", value: 11 },
  ];

  const [timezone, fetchNonSchoolDays, saveNonSchoolDays, deleteNonSchoolDays] = useSchoolCalendarStore(
    useCallback((state: SchoolCalendarStore) => [state.timezone, state.fetchNonSchoolDays, state.save, state.delete] as const, [])
  );

  const [loading, setLoading] = useState(false);
  // If we're adding or deleting a rule, don't put the table back into its loading state
  const [updating, setUpdating] = useState(false);

  // Controls for the table display
  // Default to current year
  const [selectedYear, setSelectedYear] = useState<NumericOption>({ ...years[2], label: `Year: ${years[2].value}` });
  // Defaults to no month
  const [selectedMonth, setSelectedMonth] = useState<NumericOption | null>(null);
  // All rules matching the selected year
  const [rules, setRules] = useState<NonSchoolRule[]>([]);
  // Rules with the user filters applied (e.g., filtered by month)
  const [filteredRules, setFilteredRules] = useState<NonSchoolRule[]>([]);
  // ID of a rule to visually highlight (used when a new rule is inserted)
  const [highlightRowTarget, setHighlightRowTarget] = useState<string>("");
  // Control the state of the row highlight animation
  const [transition, setTransition] = useState("0s");

  // The rule to be used in the edit and delete modals
  const [ruleToEdit, setRuleToEdit] = useState<NonSchoolRule>();

  const [openDayModal, setOpenDayModal] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const { successToast, errorToast } = useToast();
  const { t } = useTranslation();

  const handleChangeYear = (selected: NumericOption | null) => {
    if (!selected) return;
    selected.label = `Year: ${selected.value}`;
    setSelectedYear(selected);
  };

  const handleChangeMonth = (selected: NumericOption | null) => {
    if (!selected) {
      setSelectedMonth(null);
      return;
    }
    selected.label = `Month: ${selected.label ?? ""}`;
    setSelectedMonth(selected);
  };

  const handleOpenAddDay = () => {
    setRuleToEdit(undefined);
    setOpenDayModal(true);
  };

  const handleCloseDayModal = () => {
    setRuleToEdit(undefined);
    setOpenDayModal(false);
  };

  const handleOpenDeleteConfirm = (row: NonSchoolRule) => {
    if (!row) return;
    setRuleToEdit(row);
    setOpenDeleteConfirm(true);
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
    setRuleToEdit(undefined);
  };

  const handleDeleteDays = async (rule: NonSchoolRule, recurrenceId?: RecurrenceID, deleteType?: RuleUpdateType) => {
    setOpenDeleteConfirm(false);
    setLoading(true);
    setUpdating(true);

    await deleteNonSchoolDays(rule, { recurrenceId, deleteType })
      .then(() => {
        successToast({
          title: t("Delete successful"),
          description: t("The non-school day has been successfully deleted"),
        });
      })
      .catch(() => {
        errorToast({
          title: t("Please try again"),
          description: t("An unexpected error occurred while deleting a non-school day"),
        });
      });

    setRuleToEdit(undefined);
    await populateNonSchoolRules();
  };

  const handleEditOpen = (row: NonSchoolRule) => {
    setRuleToEdit(row);
    setOpenDayModal(true);
  };

  const columns: TableColumn[] = [
    {
      headerText: t("Name"),
      columnName: "name",
      sortable: true,
      searchable: false,
    },
    {
      headerText: t("Start Date"),
      columnName: "startDate",
      sortable: true,
      searchable: false,
    },
    {
      headerText: t("End Date"),
      columnName: "endDate",
      sortable: true,
      searchable: false,
    },
    { headerText: t("Operations"), columnName: "operations", sortable: false, searchable: false, alignment: "right" },
  ];

  const handleSaveDay = async (rule: NonSchoolRule, recurrenceId?: RecurrenceID, updateType?: RuleUpdateType) => {
    setOpenDayModal(false);
    setLoading(true);
    setUpdating(true);

    await saveNonSchoolDays(rule, { recurrenceId, updateType })
      .then((saved: CalendarRule) => {
        if (saved.id) {
          setHighlightRowTarget(saved.id);
        }
        successToast({
          title: t("Update successful"),
          description: t("The non-school day has been successfully saved"),
        });
      })
      .catch(() => {
        errorToast({
          title: t("Please try again"),
          description: t("An unexpected error occurred while saving a non-school day"),
        });
      });

    setRuleToEdit(undefined);
    await populateNonSchoolRules();
  };

  const filterRules = useCallback(() => {
    const rulesToShow = rules.filter((rule) => {
      return RuleMatchesFilter(rule, { year: selectedYear.value, month: selectedMonth?.value });
    });
    rulesToShow.sort((a, b) => a.startDate - b.startDate || a.name.localeCompare(b.name));
    setFilteredRules(rulesToShow);
  }, [rules, selectedYear, selectedMonth]);

  useEffect(() => {
    filterRules();
  }, [filterRules]);

  const populateNonSchoolRules = useCallback(async () => {
    setLoading(true);

    await fetchNonSchoolDays(selectedYear.value)
      .then((rules: NonSchoolRule[]) => {
        const displayRules: NonSchoolRule[] = [];

        // set rules but edit the start date and end date to be in the correct format
        rules.forEach((rule) => {
          displayRules.push(...ConvertRuleForDisplay(rule, { displayYear: selectedYear.value }));
        });

        setRules(displayRules);
      })
      .catch(() => {
        errorToast({
          title: t("Please try again"),
          description: t("An unexpected error occurred while loading non-school days"),
        });
      });

    setLoading(false);
    setUpdating(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNonSchoolDays, selectedYear.value]);

  const highlightRowAnimation = useCallback(() => {
    // If no target is set, don't do anything
    if (highlightRowTarget === "") {
      return;
    }
    setTimeout(() => {
      setTransition("2s");
    }, 1000);
    setTimeout(() => {
      setHighlightRowTarget("");
    }, 3000);
    setTimeout(() => {
      setTransition("0s");
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightRowTarget]);

  // For the initial page load and whenever the user picks a different year.
  useEffect(() => {
    setLoading(true);
    void populateNonSchoolRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear.value]);

  // Play the highlight animation whenever the ruleset changes.
  useEffect(() => {
    highlightRowAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules]);

  const dataMap = (row: NonSchoolRule, index: number) => {
    const today = dayjs().utc(true).startOf("day").unix();
    const disabled = row.startDate < today && (!row.endDate || row.endDate < today);

    return (
      <Tr
        key={index}
        backgroundColor={highlightRowTarget === row.id ? "brand.200" : "None"}
        //override Chakra's `nth-of-type(odd)` styling
        _odd={highlightRowTarget === row.id ? { backgroundColor: "brand.200" } : undefined}
        transition={transition}
      >
        <Td data-testid="non-school-day-name">
          <Text fontSize="md" as="div">
            {row?.name}
            {row?.recurrence &&
              row.recurrence.until !== row.startDate /* Don't display recurrence if the recurrence ends after this instance */ && (
                <Box pl="sp8" display="inline">
                  <Tag variant="outline" backgroundColor="brand.200" color="brand.500" size="compact">
                    <TagLabel>{t("Recurs Yearly")}</TagLabel>
                  </Tag>
                </Box>
              )}
          </Text>
        </Td>
        <Td data-testid="non-school-day-start" width="40%">
          <Text fontSize="md" as="div">
            {dayjs.unix(row.startDate).utc(false).format("MMM DD, YYYY")}
          </Text>
        </Td>
        <Td data-testid="non-school-day-end">
          <Text fontSize="md" as="div">
            {row?.endDate
              ? dayjs.unix(row.endDate).utc(false).format("MMM DD, YYYY")
              : dayjs.unix(row.startDate).utc(false).format("MMM DD, YYYY")}
          </Text>
        </Td>
        <Td>
          <Flex justifyContent="end">
            <Flex mr="sp16">
              <TableIconButton
                aria-label="Edit"
                onClick={() => handleEditOpen(row)}
                icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
                disabled={disabled}
              />
            </Flex>
            <Flex mr="sp8">
              <TableIconButton
                aria-label="Delete"
                onClick={() => handleOpenDeleteConfirm(row)}
                icon={<Icon icon="fa-trash-can" variant="solid" color="text.paragraph.light" />}
                disabled={disabled}
                data-testid={`non-school-day-delete-${index}`}
              />
            </Flex>
          </Flex>
        </Td>
      </Tr>
    );
  };

  return (
    <>
      <CardBasedPage topMargin="0">
        <Box p="sp24" color="neutrals.900">
          <Text fontFamily="Poppins" fontWeight="medium" fontSize="large">
            {t("Non-School Days")}
          </Text>
        </Box>
        <Box px="sp24" pb="sp24" color="neutrals.900">
          {t(
            "Set yearly holidays, breaks, and other non-school days. This will define the non-school days for handover to parents outside school hours."
          )}
        </Box>
        <Flex px="sp24" justifyContent="space-between">
          <Flex gap="sp8">
            <SingleSearchSelector
              options={years}
              selected={selectedYear}
              onChange={handleChangeYear}
              filterOptions={false}
              showClearIcon={false}
              disabled={loading}
              placeholder={t("Select Year")}
              name="year-selector"
            />
            <SingleSearchSelector
              options={months}
              selected={selectedMonth}
              onChange={handleChangeMonth}
              filterOptions={false}
              showClearIcon={true}
              placeholder={t("Filter by month")}
              disabled={loading}
              name="month-selector"
            />
          </Flex>
          <Flex>
            <Button
              variant="primary"
              isDisabled={!nonSchoolDaysEnabled}
              size="large"
              onClick={handleOpenAddDay}
              isLoading={loading}
              leftIcon={<Icon icon="fa-plus" />}
            >
              {t("Add Non-School Day")}
            </Button>
          </Flex>
        </Flex>
        <Box px="sp24" pb="sp8">
          <SortSearchTable
            loaded={!loading || updating}
            columns={columns}
            data={filteredRules}
            tableDataMap={dataMap}
            showSearch={false}
            tableMargin="0"
            tableTopMargin="0"
            tablePadding="0"
          />
        </Box>
      </CardBasedPage>
      <AddNonSchoolDaysModal
        open={openDayModal}
        onClose={handleCloseDayModal}
        onSave={(rule: NonSchoolRule, recurrenceId?: RecurrenceID, updateType?: RuleUpdateType) =>
          void handleSaveDay(rule, recurrenceId, updateType)
        }
        existingRule={ruleToEdit}
        timezone={timezone}
      />
      <DeleteNonSchoolDaysModal
        open={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        onDelete={(rule: NonSchoolRule, recurrenceId?: RecurrenceID, deleteType?: RuleUpdateType) =>
          void handleDeleteDays(rule, recurrenceId, deleteType)
        }
        day={ruleToEdit ? ruleToEdit : NonSchoolRulesPlaceholder}
      />
    </>
  );
};
export default NonSchoolRules;
