import { create } from "zustand";
import { COMMUNITY_TIMEOUT_MILLIS } from "../constants/CommunityConstants";
import SessionStore from "../stores/SessionStore";
import { jqXHR, ResponseError } from "../types/Api";
import { CommunityDashboardData, LinkAndClaimStats } from "../types/Community";
import Api from "../utils/Api";

export interface SearchOptions {
  term?: string;
  groupId?: string;
}

export interface CommunityDashboardStore {
  data: CommunityDashboardData;
  fetch: (options: SearchOptions) => Promise<CommunityDashboardData>;
  reset: () => void;
}

const defaultData: CommunityDashboardData = {
  linkAndClaimStats: { linkedCount: 0, claimedCount: 0 },
  latestSync: { latestSyncTimestamp: 0 },
};

export const useCommunityDashboardStore = create<CommunityDashboardStore>((set) => ({
  data: defaultData,

  fetch: async (options: SearchOptions): Promise<CommunityDashboardData> => {
    let data = defaultData,
      statusCode = 200;

    // eslint-disable-next-line
    const device: string = SessionStore.getSelectedDevice();

    const params = new URLSearchParams();
    if (!!options.groupId) {
      params.append("groupId", options.groupId);
    }

    const paramString = params.toString();

    let url = `/config/ajax/devices/${device}/users/stats`;
    if (paramString) {
      url += `?${paramString}`;
    }

    // eslint-disable-next-line
    await Api.getAsyncWithTimeout(url, COMMUNITY_TIMEOUT_MILLIS).then(
      (response: LinkAndClaimStats) => {
        data = { ...data, linkAndClaimStats: response };
      },
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (statusCode !== 200) {
      throw new ResponseError("Failed to fetch dashboard data", statusCode);
    }

    set({ data });

    return data;
  },

  reset: () => {
    set({ data: defaultData });
  },
}));
