import { GUARDIAN_SEARCH_DEFAULT_PAGE_SIZE, GUARDIAN_SEARCH_MAX_PAGE_SIZE } from "./../constants/CommunityConstants";
import { create } from "zustand";
import { jqXHR, ResponseError } from "../types/Api";
import { Guardian } from "../types/Community";
import Api from "../utils/Api";
import { useSessionStore } from "./SessionStore";
import FileSaver from "file-saver";

export const exportGuardiansURL = (): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/guardians/export`;
};

export interface SearchOptions {
  term?: string;
  page?: number;
  details?: boolean;
  pageSize?: number;
}

export interface GuardianStore {
  guardians: Guardian[];
  hasNextPage: boolean;
  search: (options: SearchOptions) => Promise<Guardian[]>;
  downloadAllGuardians: () => Promise<void>;
  setGuardian: (guardian: Guardian) => void;
  reset: () => void;
}

export const useGuardianStore = create<GuardianStore>((set, get) => ({
  guardians: [],
  hasNextPage: true,

  search: async (options: SearchOptions): Promise<Guardian[]> => {
    const pageNumber = options.page ?? 0;
    const pageSize = options.pageSize ? Math.min(options.pageSize, GUARDIAN_SEARCH_MAX_PAGE_SIZE) : GUARDIAN_SEARCH_DEFAULT_PAGE_SIZE;

    const params = new URLSearchParams({ p: String(pageNumber), p_size: String(pageSize) });

    if (options.term) {
      params.append("q", options.term);
    }
    if (options.details) {
      params.append("includeDetails", String(options.details));
    }

    let guardians: Guardian[] = [],
      hasNextPage = true,
      statusCode = 200;

    const device = useSessionStore.getState().getDevice();

    // eslint-disable-next-line
    await Api.getAsync(`/config/ajax/devices/${device.id}/guardians/search?${params.toString()}`).then(
      (response: { guardians: Guardian[] }) => {
        hasNextPage = pageSize === response.guardians.length;

        if (pageNumber > 0) {
          guardians = [...get().guardians, ...response.guardians];
        } else {
          guardians = response.guardians;
        }
      },
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (statusCode !== 200) {
      throw new ResponseError("Failed to fetch guardians", statusCode);
    }

    set({ guardians, hasNextPage });

    return guardians;
  },

  downloadAllGuardians: async (): Promise<void> => {
    const device = useSessionStore.getState().getDevice();

    const response = await fetch(exportGuardiansURL(), {
      headers: {
        Accept: "text/csv",
        Token: useSessionStore.getState().getToken(),
      },
    });

    if (!response.ok) {
      throw new ResponseError("Failed to export parent data", response.status);
    }

    FileSaver.saveAs(await response.blob(), `${device.id}_parent_data.csv`);
  },

  setGuardian: (guardian: Guardian) => {
    const guardians: Guardian[] = [];

    for (const g of get().guardians) {
      if (g.id === guardian.id) {
        guardians.push(guardian);
      } else {
        guardians.push(g);
      }
    }

    set({ guardians });
  },

  reset: () => {
    set({ guardians: [], hasNextPage: true });
  },
}));
