import { Modal, ModalBody, Text, useTranslation } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import { DisconnectEvent, DisconnectType } from "../../types/Community";

interface Props {
  event: DisconnectEvent;
  open: boolean;
  onClose: () => void;
  onConfirm: (event: DisconnectEvent) => void;
}

const ParentDisconnectModal: React.FC<Props> = ({ event, open, onClose, onConfirm }: Props) => {
  const { t } = useTranslation();

  const [saving, setSaving] = useState<boolean>(false);

  const handleConfirm = (): void => {
    setSaving(true);
    onConfirm(event);
  };

  useEffect(() => {
    if (open) {
      setSaving(false);
    }
  }, [open]);

  const studentName = event.student.firstName + " " + event.student.lastName;
  const guardianName = event.guardian.firstName + " " + event.guardian.lastName;
  const unclaim = event.type === DisconnectType.Unclaim;

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      variant="warning"
      size="md"
      headerText={unclaim ? t("Disconnect Student") : t("Remove")}
      primaryCTALabel={unclaim ? (saving ? t("Disconnecting...") : t("Disconnect")) : saving ? t("Removing...") : t("Remove")}
      primaryCTADisabled={saving}
      onPrimaryCTAClick={handleConfirm}
      secondaryCTALabel={t("Cancel")}
      secondaryCTADisabled={saving}
      onSecondaryCTAClick={onClose}
    >
      <ModalBody>
        {unclaim ? (
          <>
            <Text mb="sp24">
              {t("Disconnecting a student from a parent means the parent will not be able to see student's school time activity.")}
            </Text>
            <Text mb="sp24">{t("It will also disconnect student delegation if enabled.")}</Text>
            <Text data-testid="confirmation-question">
              {t("Are you sure you want to disconnect")} <b>{studentName}</b> {t("from")} <b>{guardianName}</b>?
            </Text>
          </>
        ) : (
          <>
            <Text mb="sp24">{t("This will remove the student from the parent completely.")}</Text>
            <Text data-testid="confirmation-question">
              {t("Are you sure you want to completely remove")} <b>{studentName}</b> {t("from")} <b>{guardianName}</b>?
            </Text>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ParentDisconnectModal;
