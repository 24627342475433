import React from "react";
import SessionStore from "../../stores/SessionStore";
import DeviceTab from "../navigation/Device";
import AccountTab from "../navigation/Account";
import NoiseFilter from "../../modules/NoiseFilter";
import GlobalDatePickerVisibilityStore from "../../stores/GlobalDatePickerVisibilityStore";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import { getDateTime } from "../../utils/DateTimeUtil";

import {
  DateTimePicker,
  Flex,
  Icon,
  LinewizeLogoWithText,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  withTranslation,
} from "@familyzone/component-library";
import { TFunction } from "i18next";
import { HeaderMenuButton, HeaderStyledButton } from "./components/HeaderMenuButton";
import { HeaderMenuItem } from "./components/HeaderMenuItem";
import Api from "../../utils/Api";
import { HeaderMenu } from "./components/HeaderMenu";
import { zIndices } from "../../utils/ZIndexUtil";

interface Props {
  path: string;
  t: TFunction;
}

interface State {
  user_status_menu_open: boolean;
  has_device: boolean;
  tools_open: boolean;

  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;

  haveCheckedDateFormat: boolean;
  useUSDateFormat: boolean;

  showGlobalDatePicker: boolean;
}

class HeaderBar extends React.Component<Props, State> {
  state: State = {
    user_status_menu_open: false,
    has_device: false,
    tools_open: false,

    startDate: useDateRangeFilterStore.getState().getStartDate(),
    endDate: useDateRangeFilterStore.getState().getEndDate(),
    startTime: useDateRangeFilterStore.getState().getStartTime(),
    endTime: useDateRangeFilterStore.getState().getEndTime(),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    showGlobalDatePicker: GlobalDatePickerVisibilityStore.isGlobalDatePickerVisible(),

    haveCheckedDateFormat: false,
    useUSDateFormat: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unsubFilterStore = () => {};

  componentDidMount() {
    /* Disabling this rule(s) because Alt store getters/setter are type unsafe, until these stores fully migrated to another state management library */
    /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
    SessionStore.listen(this.onChange);
    SessionStore.listen(this.checkDateFormat);
    GlobalDatePickerVisibilityStore.listen(this.onChange);
    /* eslint-enable */

    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.onChangeDatePicker);
  }

  componentWillUnmount() {
    /* Disabling this rule(s) because Alt store getters/setter are type unsafe, until these stores fully migrated to another state management library */
    /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
    SessionStore.unlisten(this.onChange);
    SessionStore.unlisten(this.checkDateFormat);
    GlobalDatePickerVisibilityStore.unlisten(this.onChange);
    /* eslint-enable */
    this.unsubFilterStore();
  }

  onChange = () => {
    this.setState({
      /* Disabling this rule(s) because Alt store getters/setter are type unsafe, until these stores fully migrated to another state management library */
      /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
      has_device: SessionStore.hasDevice(),
      showGlobalDatePicker: GlobalDatePickerVisibilityStore.isGlobalDatePickerVisible(),
      /* eslint-enable */
    });
  };

  checkDateFormat = () => {
    /* Disabling this rule(s) because Alt store getters/setter are type unsafe, until these stores fully migrated to another state management library */
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
    if (this.state.showGlobalDatePicker && !this.state.haveCheckedDateFormat && SessionStore.isAuthenticated()) {
      // TODO: Replace the call with `DeviceOptionsStore` in https://familyzone.atlassian.net/browse/COMMUNITY-1214
      Api.get("/managedevice/ajax/device", (result: { data: { timezone: string } }) => {
        const data = result["data"];
        const timezone = data["timezone"];
        this.setState({
          haveCheckedDateFormat: true,
          useUSDateFormat: !!timezone && (timezone.startsWith("US") || timezone.startsWith("America")),
        });
      });
    }
  };

  onChangeDatePicker = () => {
    this.setState({
      /* Disabling this rule(s) because Alt store getters/setter are type unsafe, until these stores fully migrated to another state management library */
      /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
      startDate: useDateRangeFilterStore.getState().getStartDate(),
      endDate: useDateRangeFilterStore.getState().getEndDate(),
      /* eslint-enable */
    });
  };

  determineBrandingMode = () => {
    return "navbar__branding";
  };

  handleClickStatus = () => {
    window.open("http://status.linewize.net/");
  };

  handleClickHelp = () => {
    window.open("https://help.linewize.com/");
  };

  renderContactMenu = () => {
    const { t } = this.props;
    return (
      <HeaderMenu>
        <HeaderMenuButton iconVariant="solid" icon="fa-comment">
          {t("Contact")}
        </HeaderMenuButton>
        <MenuList>
          <MenuItem flexDirection="column" alignItems="start" cursor="default">
            <Flex flexDirection="row" alignItems="center" mx="sp16" mt="sp16" mb="sp8">
              <Text fontFamily="heading">{t("USA")}:</Text>
            </Flex>
            <Flex flexDirection="row" alignItems="center" mx="sp16">
              <Icon variant="solid" color="brand.400" icon="fa-phone" mr="sp4" />
              <Text mr="sp16">
                <a href="tel: +1 844 723 3932">+1 844 723 3932</a>
              </Text>
              <Icon variant="solid" color="brand.400" icon="fa-envelope" mr="sp4" />
              <Text>
                <a href="mailto:help@linewize.com">help@linewize.com</a>
              </Text>
            </Flex>
            <Flex flexDirection="row" alignItems="center" mx="sp16" mt="sp16" mb="sp8">
              <Text fontFamily="heading">{t("New Zealand")}:</Text>
            </Flex>
            <Flex flexDirection="row" alignItems="center" mx="sp16">
              <Icon variant="solid" color="brand.400" icon="fa-phone" mr="sp4" />
              <Text mr="sp16">
                <a href="tel: +64 0800 445 206">+64 0800 445 206</a>
              </Text>
              <Icon variant="solid" color="brand.400" icon="fa-envelope" mr="sp4" />
              <Text>
                <a href="mailto:help@linewize.io">help@linewize.io</a>
              </Text>
            </Flex>
            <Flex flexDirection="row" alignItems="center" mx="sp16" mt="sp16" mb="sp8">
              <Text fontFamily="heading">{t("Australia")}:</Text>
            </Flex>
            <Flex flexDirection="row" alignItems="center" mx="sp16" mb="sp12">
              <Icon variant="solid" color="brand.400" icon="fa-phone" mr="sp4" />
              <Text mr="sp16">
                <a href="tel: 1300 687 052">1300 687 052</a>
              </Text>
              <Icon variant="solid" color="brand.400" icon="fa-envelope" mr="sp4" />
              <Text>
                <a href="mailto:help@linewize.io">help@linewize.io</a>
              </Text>
            </Flex>
          </MenuItem>
          <HeaderMenuItem flexDirection="column" alignItems="start" onClick={this.handleClickStatus}>
            <Flex flexDirection="row" alignItems="center" color="brand.500">
              <Text fontFamily="links" mr="sp4" fontWeight="medium" fontSize="sm">
                {t("Linewize status")}
              </Text>
              <Icon icon="fa-arrow-right" width="12px" />
            </Flex>
          </HeaderMenuItem>
        </MenuList>
      </HeaderMenu>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <Flex alignItems="center" bg="neutrals.0" display="flex" position="static" zIndex={zIndices.menu}>
        <Flex mr="auto" py="auto" alignItems="center">
          <Flex
            bg={this.props.path === "/devices" ? "neutrals.0" : "neutrals.900"}
            width="220px"
            height="58px"
            pl="sp12"
            alignItems="center"
          >
            <LinewizeLogoWithText color={this.props.path === "/devices" ? "brand.400" : "neutrals.0"} height="24px" width="110px" />
          </Flex>
          <Flex pl="sp12">
            <DeviceTab />
          </Flex>
        </Flex>
        <Flex flexGrow={1} />
        <Flex mx="sp12" alignItems="center">
          {this.renderContactMenu()}
          <Tooltip variant="dark" label="Opens in a new tab">
            <HeaderStyledButton iconVariant="solid" icon="fa-question" onClick={this.handleClickHelp}>
              {t("Help")}
            </HeaderStyledButton>
          </Tooltip>
        </Flex>
        {this.state.showGlobalDatePicker && (
          <>
            <Flex zIndex={zIndices.menu} mr="sp12">
              <DateTimePicker
                initialStart={getDateTime(this.state.startDate, this.state.startTime)}
                initialEnd={getDateTime(this.state.endDate, this.state.endTime)}
                useUSDateFormat={this.state.useUSDateFormat}
                onDateChange={(start, end) => {
                  const startSplit = start.split(" ", 2);
                  const endSplit = end.split(" ", 2);
                  useDateRangeFilterStore.getState().updateDateFilter({
                    startDate: startSplit[0],
                    endDate: endSplit[0],
                    startTime: startSplit[1],
                    endTime: endSplit[1],
                    relativeKey: "",
                  });
                }}
              />
            </Flex>
            <Flex ml="sp8" mr="sp16" pr="sp4">
              <NoiseFilter hideNoiseFilter={!this.state.showGlobalDatePicker} />
            </Flex>
          </>
        )}
        <AccountTab />
      </Flex>
    );
  }
}

export default withTranslation()(HeaderBar);
